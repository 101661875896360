<template>
  <div class="jaya-calendar mr-auto ml-auto h-full flex items-center">
    <vc-calendar :attributes="attributes" :columns="$screens({ default: 1, md: 2, xl: 3})" locale="fr">
      <template #day-popover>
        <div
            ref="popover"
            slot-scope="{ day, dayTitle, attributes }"
            :style="maxWidth">
          <i class="font-small-3 text-center pb-0">
            {{ dayTitle }}
          </i>
          <b-list-group>
            <b-list-group-item
                v-for="attr in attributes"
                :key="attr.key"
                :attribute="attr"
                class="text-truncate">
              {{ attr.customData.description }}
            </b-list-group-item>
          </b-list-group>
        </div>
      </template>
    </vc-calendar>
  </div>
</template>

<script>
import {BListGroup, BListGroupItem} from "bootstrap-vue";

export default {
  components: {
    BListGroup,
    BListGroupItem,
  },
  props: {
    attributes: {
      type: Array,
      default: null
    }
  },
  computed: {
    maxWidth() {
      if (this.$store.state.app.windowWidth < 490) {
        return 'max-width:' + (this.$store.state.app.windowWidth - 255) + 'px;'
      } else {
        return 'max-width: 230px;'
      }
    }
  }
}
</script>

<style lang="scss">
.vc-popover-content {
  padding: 0;
  border: none;
  font-size: 14px;
  font-weight: normal;
}

.vc-dot {
  background-color: var(--primary) !important;
}
</style>
