<template>
  <validation-observer v-slot="{invalid}">
    <div class="plant-filter w-100 mt-1">
      <app-collapse type="margin">
        <app-collapse-item title="Famille" is-visible>
          <div slot="header">
            Famille
          </div>
          <div>
            <ul>
              <li v-for="item in animalFamilies" v-bind:key="item.id">
                <b-form-checkbox v-model="filters.parents" :value="item.english" class="w-full">{{ item.french }}</b-form-checkbox>
              </li>
            </ul>
          </div>
        </app-collapse-item>
      </app-collapse>

      <b-row class="w-100 justify-content-around wrap">
        <b-col class="flex-centered mt-1" cols="auto" md="auto">
          <b-button class="float-left" variant="outline-primary" @click="removeAllFilters">Tout retirer
          </b-button>
        </b-col>
        <b-col class="flex-centered mt-1" cols="auto" md="auto">
          <b-button :disabled="invalid" class="float-right" variant="primary" @click="filterValidated">Filtrer
          </b-button>
        </b-col>
      </b-row>
    </div>
  </validation-observer>
</template>

<script type="text/javascript">
import {mapState} from "vuex";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormText,
  BInputGroup,
  BInputGroupAppend,
  BRow,
} from "bootstrap-vue";
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BCol,
    BFormCheckbox,
    BRow,
    BFormInput,
    BFormText,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup, BCard
  },
  computed: {
    ...mapState({
      user: state => state.account.user,
    }),
  },
  mounted() {
    this.filters = this.$store.state.animal.filters
  },
  data() {
    return {
      filters: {
        parents: [],
      },
      animalFamilies: [
        { french: 'Oiseaux', english: 'birds'},
        { french: 'Mammifères', english: 'mammals'},
        { french: 'Insectes', english: 'insects'},
        { french: 'Amphibiens', english: 'amphibians'},
        { french: 'Reptiles', english: 'reptiles'},
        { french: 'Gastéropodes', english: 'gastropods'},
        { french: 'Vers', english: 'worms'},
        ],
    }
  },
  methods: {
    filterValidated() {
      let filterEvent = {}
      filterEvent.filters = this.filters
      filterEvent.close = true
      this.$store.commit('animal/UPDATE_FILTERS', {
        filters: this.filters,
      })
      this.$emit('filter-validated', filterEvent)
    },
    removeAllFilters() {
      for (let i in this.filters) {
        this.filters[i] = []
      }
      let filterEvent = {}
      filterEvent.filters = this.filters
      filterEvent.close = false
      this.$store.commit('animal/UPDATE_FILTERS', {
        filters: this.filters,
      })
      this.$emit('filter-validated', filterEvent)
    }
  },
  watch: {
    temperature() {
      this.temperature_changed = true
    }
  }
}
</script>

<style lang="scss">
.plant-filter {
  .con-vs-slider {
    min-width: 1px !important;
  }

  ul {
    list-style-type: none;
    padding-left: 10px;
  }
}
</style>
