<template>
  <div v-if="this.gotPost" class="question-card max-w-md cursor-pointer">
    <b-card v-if="!piCard" class="g-card overflow-hidden mb-25" no-body @click="performMoreInfos">
      <b-row class="question-card-height" no-gutters>
        <b-col cols="3" xl="4">
          <img :alt="ImageDescription" :src="getImage" class="jaya-card-image">
        </b-col>
        <b-col class="d-flex align-items-center question-card-height pl-50" cols="9" xl="8">
          <b-card-text class="pr-1 text-truncate">
            <span class="font-medium-2"><b>{{ post.title }}</b></span> <br>
            <slot></slot>
          </b-card-text>
          <span class="question-info">
            {{ post.publisher.first_name }}
            {{ post.publisher.last_name.charAt(0) }}
            <span v-if="(post.publisher.first_name || post.publisher.last_name.charAt(0)) && getDate(post.created_at)">
              -
            </span>
            {{ getDate(post.created_at) }}
          </span>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-else class="g-card mb-25 pi-container" no-body @click="performMoreInfos">
      <img :alt="ImageDescription" :src="getImage">
    </b-card>

    <div class="question-badge">
      <feather-icon
          v-if="post.valid_answer"
          class="mr-50 text-primary"
          icon="CheckCircleIcon"
          size="20"/>
      <div
          v-if="post.is_closed"
          style="position: relative">
        <feather-icon
            class="mr-50 text-danger"
            icon="MessageSquareIcon"
            size="20"/>
        <span class="slash-through">/</span>
      </div>
    </div>

    <div v-if="piCard" class="position-absolute position-bottom-0 font-small-3s text-center text-white mb-50 ml-25">
      Identifiez-moi !
    </div>

    <b-modal
        id="popUpForumId"
        ref="questionsPopUp"
        :title="post.publisher.first_name + ' ' + post.publisher.last_name.charAt(0) + ', le '  + getDate(post.created_at)"
        hide-footer
        centered
        size="lg">
      <QuestionInfo :closeDD="closeDD" :post="postMoreInfos" @postDeleted="transmit" @edit-post-status="editPostStatus"/>
    </b-modal>
  </div>
</template>

<script type="text/javascript">
import {BCard, BCardText, BCol, BModal, BRow} from "bootstrap-vue";
import QuestionInfo from '@/components/forum/QuestionInfo.vue'

export default {
  name: "question-card",
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BModal,
    QuestionInfo
  },
  data() {
    return {
      postMoreInfos: null,
      gotPost: true,
      removeItem: -1,
      closeDD: false
    }
  },
  props: {
    title: {
      type: String,
      default: "Default Title"
    },
    subtitle: {
      type: String,
      default: "Default subtitle"
    },
    post: {
      type: Object,
      default: () => {
      }
    },
    thumbnail: {
      type: String,
      default: "question_default"
    },
    piCard: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    getImage() {
      if (this.post.photo_url) {
        return process.env.VUE_APP_BACKEND_URL + '/..' + this.post.photo_url + "&size=200"
      } else {
        return require("@/assets/images/default_pictures/question_default.svg")
      }
    },
    ImageDescription() {
      return "Image de " + this.title
    }
  },
  methods: {
    getDate(date) {
      let date_obj = new Date(date)
      return date_obj.toLocaleDateString()
    },
    performMoreInfos() {
      this.postMoreInfos = this.post
      this.$refs.questionsPopUp.show()
    },
    transmit(event) {
      this.popUpMoreInfo = false
      this.removeItem = event
      this.$emit('postDeleted')
    },
    editPostStatus(event) {
      if (event["close"] !== undefined) {
        this.post.is_closed = event["close"]
      }
      if (event["valid"] !== undefined) {
        this.post.valid_answer = event["valid"]
      }
    }
  },
  watch: {
    closeDD(value) {
      if (value) {
        setTimeout(() => {
          this.closeDD = false
        }, 10)
      }
    }
  }
}
</script>

<style lang="scss">
.question-card {
  position: relative;
  padding-bottom: 3px;
  width: 100%;
  margin: auto;

  .question-card-height {
    height: 84px;
    @media screen and (min-width: 1200px) {
      height: 112px;
    }
  }

  .question-info {
    position: absolute;
    right: 5px;
    top: 62px;
    @media screen and (min-width: 1200px) {
      top: 90px;
    }
  }

  .slash-through {
    position: absolute;
    color: var(--danger);
    font-weight: 700;
    font-size: 20px;
    left: 7px;
    top: -3px;
  }

  .question-badge {
    position: absolute;
    top: 5px;
    right: 0;
    display: flex;
  }

  .pi-container {
    position:relative;
    overflow:hidden;
    padding-bottom:100%;
  }
  .pi-container img{
    position:absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
