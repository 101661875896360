<template>
  <div class="forum">
    <JayaTitle classType="page-title" moreInfo="Vous allez ici participer à un ecosystème d’utilisateurs en posant vos questions et en répondant à celles des autres membres." titleName="Coup de pousse"></JayaTitle>
    <div>
      <div class="max-w-2xl">
        <div class="d-flex swiper-no-swiping" style="overflow-x: scroll;overflow-y: hidden;">
          <b-col v-for="post in getPIPosts" v-bind:key="post.id" cols="4" sm="3" md="2" class="card-list px-25">
            <QuestionCard :post="post" :title="post.title" pi-card/>
          </b-col>
          <b-col cols="4" sm="3" md="2" class="card-list px-25" v-if="nextPI">
            <b-button
                variant="outline-primary"
                class="h-100 w-100"
                @click="getNextPIPosts">
              <feather-icon class="text-primary" icon="MoreHorizontalIcon" size="50"/>
            </b-button>
          </b-col>
        </div>
        <SearchBar :check="true" class="top-0 w-full" @update-search="search" @local-only="filterPosts"/>

        <b-tabs ref="questionTabs" fill>
          <b-card
              body-class="p-0 bg-transparent"
              @click="$refs.popUpNewQuestion.show()"
              class="max-w-md mb-1 bg-transparent shadow-none cursor-pointer">
            <label for="click_to_post"> Solicitez l'aide de la communauté grâce au forum : </label>
            <b-form-textarea
                style="pointer-events: none;"
                disabled
                no-resize
                class="bg-white cursor-pointer"
                id="click_to_post"/>
          </b-card>
          <b-tab title="Questions">
            <b-alert
                v-if="filtred"
                show
                class="text-center max-w-md p-50"
                variant="info">
              Questions des jardiniers autour de vous
            </b-alert>
            <b-row class="max-w-md">
              <b-col v-for="post in getPosts" v-bind:key="post.id" cols="12" class="card-list px-25">
                <QuestionCard :post="post" :title="post.title"/>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Mes messages">
            <div v-for="post in getOwnQuestions" v-if='gotPost' :key="post.id" class="card-list">
              <QuestionCard :post="post" :title="post.title" @postDeleted="removePost"/>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <b-modal
        ref="popUpNewQuestion"
        centered
        hide-footer
        title="Poser une question">
      <NewQuestion @new-post="updateOwnQuestions"/>
    </b-modal>

  </div>
</template>

<script>
import { BAlert, BButton, BCard, BCol, BFormTextarea, BModal, BRow, BTab, BTabs } from 'bootstrap-vue'
import JayaTitle from '@/components/global/JayaTitle.vue'
import SearchBar from '@/components/global/SearchBar.vue'
import QuestionCard from '@/components/forum/QuestionCard.vue'
import NewQuestion from '@/components/forum/NewQuestion.vue'

export default {
  name: 'Forum',
  components: {
    BCard,
    BButton,
    BFormTextarea,
    BRow,
    BCol,
    BAlert,
    BTabs,
    BTab,
    BModal,
    SearchBar,
    QuestionCard,
    JayaTitle,
    NewQuestion
  },
  props: {
    searchLimit: {
      type: Number,
      default: 12
    },
  },
  data() {
    return {
      posts: [],
      PIPosts: [],
      gotPost: false,
      waitScroll: false,
      next: null,
      nextPI: null,
      searchOffset: 0,
      PISearchOffset: 0,
      ownQuestions: [],
      gotOwnQuestions: false,
      nextOwnQuestions: null,
      searchOffsetOwnQuestions: 0,
      searchText: "",
      searchTextOwnQuestions: "",
      filtred: false
    }
  },
  mounted() {
    let payload = {
      limit: this.searchLimit
    }
    this.$store.dispatch("forum/getQuestions",  {...payload, ...{type: ['WA','TR','HA','FL','OT']}})
        .then(response => {
          this.posts = response.data.results
          this.gotPost = true
          this.next = response.data.next
          setTimeout(() => document.getElementById("swiper").swiper.resize.resizeHandler())
        })
        .catch(() => {
          this.gotPost = false
        })
    this.$store.dispatch("forum/getQuestions",  {...payload, ...{type: ['PI']}})
        .then(response => {
          this.PIPosts = response.data.results
          this.nextPI = response.data.next
          setTimeout(() => document.getElementById("swiper").swiper.resize.resizeHandler())
        })
        .catch(() => {
          this.gotPost = false
        })
    this.$store.dispatch("forum/getQuestions", {...payload, ...{user: true}})
        .then(response => {
          this.ownQuestions = response.data.results
          this.gotOwnQuestions = true
          this.nextOwnQuestions = response.data.next
        })
        .catch(() => {
          this.gotOwnQuestions = false
        })
  },
  computed: {
    getPosts() {
      return this.posts
    },
    getPIPosts() {
      return this.PIPosts
    },
    getOwnQuestions() {
      return this.ownQuestions
    }
  },
  methods: {
    updateOwnQuestions() {
      let payload = {
        limit: this.searchLimit,
        user: true
      }
      this.$store.dispatch("forum/getQuestions", payload)
          .then(response => {
            this.ownQuestions = response.data.results
            this.nextOwnQuestions = response.data.next
            this.$refs.popUpNewQuestion.hide()
          })
          .catch(() => {
            this.$notify({
              title: "Erreur",
              text: "Nous n'avons pas pu récupérer les nouvelles questions",
              color: "danger",
              time: 4000
            })
            this.$refs.popUpNewQuestion.hide()
          })
    },
    filterPosts(event) {
      let payload = {
        limit: this.searchLimit,
      }
      if (event) {
        payload.local = event
      }

      this.$store.dispatch("forum/getQuestions", {...payload, ...{type: ['WA','TR','HA','FL','OT']}})
          .then(response => {
            this.posts = response.data.results
            this.next = response.data.next
            this.filtred = event
          })
          .catch(() => {
            this.$notify({
              title: "Erreur",
              text: "Nous n'avons pas pu récupérer les nouvelles questions",
              color: "danger",
              time: 4000
            })
          })
    },
    postUserAndDate(str1, str2) {
      if (str2 !== "aujourd'hui") {
        return str1 + " le " + str2
      } else {
        return str1 + " " + str2
      }
    },
    getNextPIPosts() {
      let payload = {
        limit: this.searchLimit,
        offset: this.PISearchOffset + this.searchLimit
      }
      if(this.nextPI) {
        this.$store.dispatch("forum/getQuestions",  {...payload, ...{type: ['PI']}})
            .then(response => {
              this.nextPI = response.data.next;
              this.PISearchOffset = this.PISearchOffset + this.searchLimit
              this.PIPosts = this.PIPosts.concat(response.data.results)
            })
      }
    },
    search(searchText) {
      if (this.$refs.questionTabs && this.$refs.questionTabs.currentTab === 0) {
        this.searchText = searchText
        this.searchOffset = 0
        let payload = {
          search: this.searchText,
          limit: this.searchLimit,
        }
        this.$store.dispatch("forum/getQuestions", {...payload, ...{type: ['WA','TR','HA','FL','OT']}})
            .then(response => {
              this.posts = response.data.results
              this.next = response.data.next
            })
            .catch(() => {
              this.posts = []
              this.$notify({
                "title": "Erreur",
                "text": "La recherche n'a pas abouti, veuillez réessayer",
                "color": "danger",
                "time": 4000
              })
            })
      } else {
        this.searchTextOwnQuestions = searchText
        this.searchOffset = 0
        let payload = {
          search: this.searchTextOwnQuestions,
          limit: this.searchLimit,
          user: true
        }
        this.$store.dispatch("forum/getQuestions", payload)
            .then(response => {
              this.ownQuestions = response.data.results
              this.nextOwnQuestions = response.data.next
            })
            .catch(() => {
              this.ownQuestions = []
              this.$notify({
                "title": "Erreur",
                "text": "La recherche n'a pas abouti, veuillez réessayer",
                "color": "danger",
                "time": 4000
              })
            })
      }
    },
    scroll(event) {
      /*
      If we are at the bottom of the scroll bar during scrolling, load the next question.
      If we are on the questions tab, we load the questions from other users, on 'mes messages'
      we load the questions of the user
      */
      if (this.next && document.body.scrollHeight - window.scrollY - window.innerHeight < 50 && this.$refs.questionTabs.currentTab === 0) {
        if (!this.waitScroll) {
          this.waitScroll = true
          this.currentScroll = event.target.scrollTop
          let payload = {
            limit: this.searchLimit,
            offset: this.searchOffset + this.searchLimit
          }
          if (this.searchText !== "") {
            payload.search = this.searchText
          }
          this.$store.dispatch("forum/getQuestions",  {...payload, ...{type: ['WA','TR','HA','FL','OT']}})
              .then(response => {
                this.next = response.data.next;
                this.searchOffset = this.searchOffset + this.searchLimit
                this.posts = this.posts.concat(response.data.results)
                this.waitScroll = false
              }).catch(() => {
            this.waitScroll = false
          })
        }
      }
      if (this.nextOwnQuestions && event.target.scrollTop + event.target.offsetHeight > event.target.scrollHeight - 50 && this.$refs.questionTabs.currentTab === 1) {
        if (!this.waitScroll) {
          this.waitScroll = true
          this.currentScroll = event.target.scrollTop
          let payload = {
            limit: this.searchLimit,
            offset: this.searchOffset + this.searchLimit,
            user: true
          }
          if (this.searchTextOwnQuestions !== "") {
            payload.search = this.searchText
          }
          this.$store.dispatch("forum/getQuestions", payload)
              .then(response => {
                this.nextOwnQuestions = response.data.next;
                this.searchOffsetOwnQuestions = this.searchOffsetOwnQuestions + this.searchLimit
                this.ownQuestions = this.ownQuestions.concat(response.data.results)
                this.waitScroll = false
              }).catch(() => {
            this.waitScroll = false
          })
        }
      }
    },
    removePost() {
      this.$store.dispatch("forum/getQuestions", {limit: this.searchLimit, user: true})
          .then(response => {
            this.ownQuestions = response.data.results
            this.gotOwnQuestions = true
            this.nextOwnQuestions = response.data.next
          })
          .catch(() => {
            this.gotOwnQuestions = false
          })
    }
  }
}
</script>

<style lang="scss">
.forum {
  width: 100%;

  .card-list {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}
</style>
