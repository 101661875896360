<template>
  <div v-if="event">
    <!-- Photo de garde -->
    <div class="jaya-top-row">
      <viewer :images="getImage" class="jaya-container">
        <img v-for="src in getImage" :key="src" :alt="event.title" :src="src" class="jaya-image">
      </viewer>
    </div>

    <h4 class="mt-50 mb-1">{{ event.title }}</h4>

    <p v-for="(part,id) in event.description.split('\n')" :key="id" class="mt-1 text-justify" v-html="paragraphWithLink(part)"/>

    <b-row class="mt-1" noGutters>
      <b-col class="text-center d-flex align-items-center justify-content-center" cols="2">
        <feather-icon
            icon='ClockIcon'
            size='24'/>
      </b-col>
      <b-col class="text-center d-flex align-items-center justify-content-center">
        <div> {{ event.start_date.toLocaleString({hour12: false}) }}</div>
        <div class="px-50"> -</div>
        <div v-if="event.start_date !== event.end_date">
          {{ event.end_date.toLocaleString() }}
        </div>
      </b-col>
    </b-row>

    <b-row class="mb-50" noGutters>
      <b-col class="text-center d-flex align-items-center justify-content-center" cols="2">
        <feather-icon
            icon='MapPinIcon'
            size='24'/>
      </b-col>
      <b-col class="text-center">
        {{ event.location_address }}
      </b-col>
    </b-row>

    <b-row v-if="event.partners.length > 0" class="mb-50" noGutters>
      <b-col class="text-center d-flex align-items-center justify-content-center" cols="2">
        <feather-icon
            icon='LinkIcon'
            size='24'/>
      </b-col>
      <b-col class="text-center">
        <em>En partenariat avec {{ event.partners.map(p => p.name).join(', ') }}.</em>
      </b-col>
    </b-row>

    <b-row noGutters>
      <b-col class="text-center d-flex align-items-center justify-content-center" cols="2">
        <feather-icon
            icon='UserIcon'
            size='24'/>
      </b-col>
      <b-col class="text-center">
        <span v-if="event.nmax_participants">
          <strong> Places restreintes:</strong> {{ event.nmax_participants }} places au total,
          {{ event.participants_count }} inscrit(s) pour l(instant
        </span>
        <span v-else>
        <strong> Places illimités: </strong>
        {{ event.participants_count }} inscrit(s) pour l'instant
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script type="text/javascript">
import {BCol, BRow} from "bootstrap-vue";
import DOMPurify from 'dompurify';

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    event: {
      type: Object,
      default: null
    }
  },
  computed: {
    getImage() {
      if (this.event.photo_url) {
        return [process.env.VUE_APP_BACKEND_URL + '/..' + this.event.photo_url + "&size=600"]
      }
      return [require("@/assets/images/default_pictures/event_default.svg")]
    }
  },
  methods: {
    paragraphWithLink(paragraph)  {
      let urlRegex = /((https?:\/\/)[^\s]+)/g;
      let paragraphWithLinks = paragraph.replace(urlRegex, '<a href="$1" id="paragraphLink">$1</a>');
      return DOMPurify.sanitize(paragraphWithLinks, {ALLOWED_TAGS: ['a'], ALLOWED_ATTR: ['href', 'id']});
    }
  }
}
</script>
