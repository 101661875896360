<template>
  <div class="max-w-md">
    <b-card class="mb-0" noBody>
      <b-overlay v-b-hover="loadOverlay" :show="imageOverlayShow">
        <img :alt="getImageDescription" :src="getImage" class="card-img-top jaya-cover">
        <template #overlay>
          <div class="text-center">
            <feather-icon
                class="text-secondary"
                icon="CrosshairIcon"
                size="100"/>
          </div>
        </template>
      </b-overlay>

      <b-card-body class="border-top-light">
        <b-card-title class="mb-50 font-small-4">
          <b-row align-h="between" align-v="center" no-gutters>
            <b-col cols="3">
              <img :alt="getLocalAuthorityImageDescription" :src="getLocalAuthorityImage"
                  class="event-local-authority-image">
            </b-col>
            <b-col cols="9">
              <span class="overflow-hidden">{{ event.title }}</span>
            </b-col>
          </b-row>
        </b-card-title>


        <b-card-text class="pt-1" style="border-top: 1px solid #dae1e7;">
          <b-row align-h="between" no-gutters>
            <b-col class="text-center" cols="6">
              <div class="font-small-3 font-italic">à partir du</div>
              <strong>{{ event.start_date.toLocaleString().split(',')[0].split(' ')[0] }}</strong>
            </b-col>
            <b-col class="text-center d-flex justify-content-center align-items-center" cols="6">
              <div>
                <feather-icon
                    v-if="event.is_subscribed"
                    class="text-center text-primary"
                    icon="CheckCircleIcon"
                    size="24"/>
                <span v-if="event.is_subscribed" class="text-center text-primary font-small-3 ml-50"> Inscrit </span>
              </div>
              <div v-if="limitedParticipants(event) & !event.is_subscribed">
                <div v-if="!checkFullEvent(event)">
                  <span :class="'text-center text-primary '"> {{ event.nmax_participants - event.participants_count }} / {{
                      event.nmax_participants
                    }}</span>
                  <span :class="'text-center font-small-3 text-primary'"> place(s) restante(s) </span>
                </div>
                <div v-else>
                  <span :class="'text-center text-warning'"> Complet </span>
                </div>
              </div>
              <div v-if="!limitedParticipants(event) & !event.is_subscribed">
                <span class="text-center text-primary font-small-3"> Places</span>
                <span class="text-center text-primary font-small-3"> disponibles </span>
              </div>
            </b-col>
          </b-row>
        </b-card-text>

        <b-card-footer class="p-0 pt-1">
          <b-row align-h="center" no-gutters>
            <b-col class="text-center" cols="4">
              <b-button
                  class="p-0"
                  variant="flat-primary"
                  @click="popUpMoreInfo = true">
                <feather-icon
                    v-b-hover="infoButtonHoverHandle"
                    :class="infoButtonHover ? 'text-secondary' : 'text-info'"
                    icon="InfoIcon"
                    size="28"/>
              </b-button>
            </b-col>
            <b-col class="text-center" cols="8">
              <b-button
                  :disabled="checkFullEvent(event)"
                  :variant="event.is_subscribed ? 'secondary' : 'primary'"
                  size="sm"
                  @click="event.is_subscribed ? unsubscribe() : subscribe()">
                {{ event.is_subscribed ? "Se désinscrire" : "S'inscrire" }}
              </b-button>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-card-body>
    </b-card>

    <b-modal
        ref="popUp"
        v-model="popUpMoreInfo"
        :title="event.creator_info.name"
        body-class="pt-0"
        centered
        hide-footer
        scrollable>
      <EventInfo :event="event" @updateSubscribed="updateSubscribed"/>
    </b-modal>
  </div>
</template>

<script type="text/javascript">
import EventInfo from '@/components/events/EventInfo.vue'
import PlaceInfo from "@/components/places/PlaceInfo";
import {
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardText,
  BCardTitle,
  BCol,
  BModal,
  BOverlay,
  BRow
} from "bootstrap-vue";
import JayaDivider from "@/components/global/JayaDivider";

export default {
  name: "event-card",
  components: {
    JayaDivider,
    EventInfo,
    PlaceInfo,
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BCardFooter,
    BModal,
    BButton,
    BRow,
    BCol,
    BOverlay,
  },
  props: {
    event: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      popUpMoreInfo: false,
      localAuthPhoto: require('@/assets/images/default_pictures/local_authority_default.jpg'),
      imageOverlayShow: false,
      infoButtonHover: false,
      localAuthorityPartnerPhotoUrl: null,
    }
  },
  computed: {
    getImage() {
      if (this.event.photo_url) {
        return process.env.VUE_APP_BACKEND_URL + '/..' + this.event.photo_url + "&size=600"
      }
      return require("@/assets/images/default_pictures/event_default.svg")
    },
    getImageDescription() {
      return "Image de " + this.event.title
    },
    getLocalAuthorityImage() {
      //check if the localAuthority is subscribed
      const isLAsubscribed = this.$store.state.localAuthority.subscriptions.find(localAuthority => localAuthority.id === this.event.creator_info.id);
      let url = null
      if (isLAsubscribed) {
        url = this.$store.state.localAuthority.subscriptions.find(localAuthority => localAuthority.id === this.event.creator_info.id).photo_url;
      }
      if (isLAsubscribed===undefined) {
        url = this.localAuthorityPartnerPhotoUrl
      }
      return url
          ? process.env.VUE_APP_BACKEND_URL + '/..' + url + "&size=150"
          : require('@/assets/images/default_pictures/local_authority_default.jpg')
    },
    getLocalAuthorityImageDescription() {
      return "Logo de " + this.event.creator_info.name
    }
  },
  methods: {
    updateSubscribed(eventId) {
      this.popUpMoreInfo = false
      this.$emit('updateSubscribed', eventId)
      if (this.event.is_subscribed) {
        this.event.participants_count += 1
      } else {
        this.event.participants_count -= 1
      }
    },
    subscribe() {
      this.$store.dispatch("event/subscribe", this.event.id)
          .then(() => {
            this.$notify({
              time: 4000,
              title: "Abonnement",
              color: "primary",
              text: "Vous êtes maintenant inscrit à l'évènement " + '"' + this.event.title + '"'
            })
            this.updateSubscribed(this.event.id)
          }).catch(error => {
        if (error.response && error.response.status === 403 && error.response.data.detail === "Event full") {
          this.$notify({
            time: 4000,
            color: "danger",
            title: "Evènement complet",
            text: "Cet évènement a eu trop de succès ! "
          })
        }
      })
    },
    unsubscribe() {
      this.$store.dispatch("event/unsubscribe", this.event.id)
          .then(() => {
            this.$notify({
              time: 4000,
              title: "Abonnement",
              color: "warning",
              text: "Vous ne participez plus à l'évènement " + '"' + this.event.title + '"'
            })
            this.updateSubscribed(this.event.id)
          }).catch(() => {
        this.$notify({
          time: 4000,
          color: "danger",
          title: "Erreur",
          text: "Une erreur est survenue à la désinscription de l'évènement, veuillez réessayer plus tard"
        })
      })
    },
    limitedParticipants(event) {
      return event.nmax_participants !== null
    },
    checkFullEvent(event) {
      return this.limitedParticipants(event) && (event.nmax_participants - event.participants_count === 0 && !event.is_subscribed)
    },
    loadOverlay(val) {
      this.imageOverlayShow = val
    },
    infoButtonHoverHandle(val) {
      this.infoButtonHover = val
    }
  },
  mounted() {
    const isLAsubscribed = this.$store.state.localAuthority.subscriptions.find(localAuthority => localAuthority.id === this.event.creator_info.id);
    if (!isLAsubscribed) {
      this.$store.dispatch("localAuthority/fetchLocalAuthority", this.event.creator_info.id)
          .then(localAuthority => {
            this.localAuthorityPartnerPhotoUrl = localAuthority.photo_url
          })
          .catch(() => {
            this.$notify({
              time: 4000,
              color: "danger",
              title: "Erreur",
              text: "Une erreur est survenue lors de la récupération des collectivités"
            })
          })
    }
  }
}
</script>