<template lang="html">
  <div class="jayaccueil">
    <transition name="slide-fade">
      <div v-if="showMenu" class="main-tabs bg-light fixed-top">
        <b-tabs ref="contentTabs" fill>
          <b-tab :active="openPage === 0" @click="toSlide(0)" id="gardenTab">
            <template #title>
              <feather-icon class="text-primary" icon="HomeIcon" size="20" id="gardenTab"/>
            </template>
          </b-tab>
          <b-tab :active="openPage === 1" @click="toSlide(1)">
            <template #title>
              <feather-icon class="text-primary" icon="CalendarIcon" size="20" id="aroundMeTab"/>
            </template>
          </b-tab>
          <b-tab :active="openPage === 2" @click="toSlide(2)">
            <template #title>
              <feather-icon class="text-primary" icon="MessageCircleIcon" size="20" id="forumTab"/>
            </template>
          </b-tab>
          <b-tab :active="openPage === 3" @click="toSlide(3)">
            <template #title>
              <feather-icon class="text-primary" icon="BookOpenIcon" size="20" id="encyclopediaTab"/>
            </template>
          </b-tab>
        </b-tabs>
      </div>
    </transition>

    <swiper
        id="swiper"
        ref="contentSwiper"
        @resize="resize"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="swiperOption"
        class="swiper-box mt-2"
        v-on:slide-change="changeTab()">
      <swiper-slide class="swiper-item" data-hash="jardin">
        <MyGarden
            v-if="$route.hash.includes('#jardin')  || $route.hash === ''"
            ref="garden"
            v-on:myGardenHideElements="hideFixedElements"
            v-on:myGardenShowElements="showFixedElements"/>
      </swiper-slide>
      <swiper-slide class="swiper-item" data-hash="autour-de-moi">
        <AroundMe
            v-if="$route.hash.includes('#autour-de-moi')"
            ref="events"/>
      </swiper-slide>
      <swiper-slide class="swiper-item" data-hash="forum">
        <Forum
            v-if="$route.hash.includes('#forum')"
            ref="forum"/>
      </swiper-slide>
      <swiper-slide class="swiper-item" data-hash="encyclopedie">
        <Encyclopedia
            v-if="$route.hash.includes('#encyclopedie')"
            ref="encyclopedia"/>
      </swiper-slide>
    </swiper>

    <div v-if="$route.hash.includes('#jardin')" v-show="showingFixedElements"
         class="jaya-fixed-button">
      <div class="d-flex justify-content-center mb-4">
        <b-button
            style="pointer-events: all;"
            variant="primary"
            @click="$refs.garden.checkPermissions()">
          <feather-icon
              class="mr-50"
              icon="CameraIcon"
              size="16"/>
          <span class="align-middle"> Identifier une plante </span>
        </b-button>
      </div>
    </div>
    <HomeOnBoarding v-if="addOnBoarding"/>
  </div>
</template>

<script>
import { BButton, BTab, BTabs } from 'bootstrap-vue'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import MyGarden from "@/views/jardins/MyGarden.vue"
import Forum from "@/views/forum/Forum.vue"
import AroundMe from "@/views/local-authorities/AroundMe.vue"
import HomeOnBoarding from "@/components/onboarding/HomeOnBoarding.vue";
import Encyclopedia from "@/views/encyclopedie/Encyclopedia.vue"
import {pauseDifferentOnBoarding} from "@/globalMethods";
import store from "@/store";

export default {
  components: {
    BButton,
    BTabs,
    BTab,
    Swiper,
    SwiperSlide,
    MyGarden,
    HomeOnBoarding,
    Forum,
    AroundMe,
    Encyclopedia
  },
  data() {
    return {
      addOnBoarding: false,
      showMenu: true,
      pages: {
        jardin: 0,
        'autour-de-moi': 1,
        forum: 2,
        encyclopedie: 3
      },
      scrollPosition: 0,
      openPage: 0,
      swiperOption: {
        allowTouchMove: true,
        hashNavigation: {
          watchState: true,
        },
        breakpoints: {
          768: {
            allowTouchMove: false,
          }
        }
      },
      showingFixedElements: false,
      pauseOnBoardings: []
    }
  },
  beforeMount() {
    this.openPage = this.pages[this.$route.hash.split('#')[1]]
  },
  mounted() {
    window.addEventListener('scroll', this.scroll);
    if (!this.$store.state.account.onBoarding.home) {
      this.$store.dispatch("account/startOnBoarding", "home")
    }
    setTimeout(() => {
      this.addOnBoarding = true
    }, 10)

  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll);
  },
  methods: {
    changeTab() {
      if (this.$refs.contentTabs) {
        this.$refs.contentTabs.leftx = this.$refs.contentSwiper.$swiper.activeIndex * this.$refs.contentTabs.widthx
        this.$refs.contentTabs.currentTab = this.$refs.contentSwiper.$swiper.activeIndex
      }
    },
    toSlide(i) {
      this.$refs.contentSwiper.$swiper.slideTo(i, 0)
    },
    scroll(event) {
      //For scolling events in children
      if (this.$route.hash.includes('#autour-de-moi')) {
        this.$refs.events.scroll(event)
      } else if (this.$route.hash.includes('#forum')) {
        this.$refs.forum.scroll(event)
      } else if (this.$route.hash.includes('#encyclopedie')) {
        this.$refs.encyclopedia.scroll(event)
      }
      // for hidding menu
      this.showMenu = !(window.scrollY > this.scrollPosition && this.$store.state.app.windowWidth < 768)
      this.scrollPosition = window.scrollY
      if (this.scrollPosition < 50) this.showMenu = true
    },
    showFixedElements() {
      this.showingFixedElements = true
    },
    hideFixedElements() {
      this.showingFixedElements = false
    },
    resize() {
      setTimeout(() => {
        this.$refs.contentSwiper.updateSwiper();
      },200)

    }
  },
  watch: {
    '$store.state.app.windowWidth'() {
      this.$refs.contentTabs.activateTab(this.$refs.contentTabs.currentTab)
    },
    '$route.hash'(val) {
      if (val.includes('#jardin') || val === "") {
        this.toSlide(0)
        this.openPage = 0
      }
      if (val.includes('#autour-de-moi')) {
        this.toSlide(1)
        this.openPage = 1
      }
      if (val.includes('#forum')) {
        this.toSlide(2)
        this.openPage = 2
      }
      if (val.includes('#encyclopedie')) {
        this.toSlide(3)
        this.openPage = 3
      }
    },
    showMenu(val) {
      if (!val) {
        this.pauseOnBoardings = this.$pauseDifferentOnBoarding("")
      } else {
        for (const pausedOnboarding of this.pauseOnBoardings) {
          setTimeout(() => {this.$store.commit("account/START_ONBOARDING", pausedOnboarding)}, 500)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.1s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-84px);
}

.jayaccueil {
  .main-tabs {
    max-height: 35px !important;
    z-index: 940;
    top: 4.55rem;
  }

  .swiper-box {
    padding-top: 10px;
  }

  .swiper-item {
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
  }
}
</style>
