<template>
  <div class="place-info">
    <!-- Photo de garde -->
    <div class="jaya-top-row">
      <viewer :images="getImage" class="jaya-container">
        <img v-for="src in getImage" :key="src" :alt="place.title" :src="src" class="jaya-image">
      </viewer>
    </div>

    <h4 class="mt-50 mb-1">{{ place.title }}</h4>

    <p class="font-italic" v-if="place.type !== 'OT'">
      {{ $store.getters["place/getTypeName"](place) }}
    </p>

    <p v-for="(part,id) in place.description.split('\n')" :key="id" class="m-0 mt-1 text-justify" v-html="paragraphWithLink(part)"/>

    <b-row v-if="place.location_address" noGutters class="mt-1">
      <b-col class="text-center d-flex align-items-center justify-content-center" cols="2">
        <feather-icon
            icon='MapPinIcon'
            size='24'/>
      </b-col>
      <b-col class="text-center">
        {{ place.location_address }}
      </b-col>
    </b-row>
  </div>
</template>

<script type="text/javascript">
import {BCol, BRow} from "bootstrap-vue";
import DOMPurify from 'dompurify';


export default {
  name: "place-info",
  components: {
    BRow,
    BCol,
  },
  props: {
    place: {
      type: Object,
      default: null
    }
  },
  computed: {
    getImage() {
      if (this.place.photo_url) {
        return [process.env.VUE_APP_BACKEND_URL + '/..' + this.place.photo_url + "&size=600"]
      }
      return [require("@/assets/images/default_pictures/place_default.svg")]
    },
  },
  methods: {
    paragraphWithLink(paragraph)  {
      let urlRegex = /((https?:\/\/)[^\s]+)/g;
      let paragraphWithLinks = paragraph.replace(urlRegex, '<a href="$1" id="paragraphLink">$1</a>');
      return DOMPurify.sanitize(paragraphWithLinks, {ALLOWED_TAGS: ['a'], ALLOWED_ATTR: ['href', 'id']});
    }
  }
}
</script>
