<template>
  <div class="max-w-md">
    <b-card class="mb-0" noBody>
      <b-overlay v-b-hover="loadOverlay" :show="imageOverlayShow">
        <img :alt="getImageDescription" :src="getImage" class="card-img-top jaya-cover">
        <template #overlay>
          <div class="text-center">
            <feather-icon
                class="text-secondary"
                icon="CrosshairIcon"
                size="100"/>
          </div>
        </template>
      </b-overlay>

      <b-card-body class="border-top-light">
        <b-card-title class="mb-50 font-small-4 height-50">
          <b-row align-h="between" align-v="center" no-gutters>
            <b-col cols="3">
              <img :alt="getLocalAuthorityImageDescription" :src="getLocalAuthorityImage"
                  class="event-local-authority-image">
            </b-col>
            <b-col cols="9">
              <span class="overflow-hidden">{{ place.title }}</span>
            </b-col>
          </b-row>
        </b-card-title>

        <b-card-footer class="p-0 pt-50 overflow-hidden">
          <b-row align-h="start" align-v="center" no-gutters>
            <b-col cols="9">
              <div class="font-small-2">
                {{ $store.getters["place/getTypeName"](place) }}
              </div>
            </b-col>
            <b-col class="text-right d-flex align-items-center justify-content-center" cols="3">
              <b-button
                  class="p-0"
                  variant="flat-primary"
                  @click="popUpMoreInfo = true">
                <feather-icon
                    v-b-hover="infoButtonHoverHandle"
                    :class="infoButtonHover ? 'text-primary' : 'text-secondary'"
                    icon="InfoIcon"
                    size="30"/>
              </b-button>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-card-body>
    </b-card>

    <b-modal
        ref="popUp"
        v-model="popUpMoreInfo"
        :title="place.creator_info.name"
        body-class="pt-0"
        centered
        hide-footer
        scrollable>
      <PlaceInfo :place="place"/>
    </b-modal>
  </div>
</template>

<script type="text/javascript">
import PlaceInfo from '@/components/places/PlaceInfo.vue'
import {
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardText,
  BCardTitle,
  BCol,
  BModal,
  BOverlay,
  BRow
} from "bootstrap-vue";

export default {
  name: "place-card",
  components: {
    PlaceInfo,
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BCardFooter,
    BModal,
    BRow,
    BCol,
    BOverlay,
    BButton,
  },
  props: {
    place: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      popUpMoreInfo: false,
      imageOverlayShow: false,
      infoButtonHover: false
    }
  },
  computed: {
    getImage() {
      if (this.place.photo_url) {
        return process.env.VUE_APP_BACKEND_URL + '/..' + this.place.photo_url + "&size=600"
      }
      return require("@/assets/images/default_pictures/place_default.svg")
    },
    getImageDescription() {
      return "Image de " + this.place.title
    },
    getLocalAuthorityImage() {
      //Todo: in case of change in editZipCode, subascriptions not updated (even if fetchSubscription is done.
      //This results in an error in "this.$store.state.localAuthority.subscriptions.find("
      var url = this.$store.state.localAuthority.subscriptions.find(localAuthority => localAuthority.id === this.place.creator_info.id).photo_url
      return url
          ? process.env.VUE_APP_BACKEND_URL + '/..' + url + "&size=150"
          : require('@/assets/images/default_pictures/local_authority_default.jpg')
    },
    getLocalAuthorityImageDescription() {
      return "Logo de " + this.place.creator_info.name
    }
  },
  methods: {
    updateSubscribed() {
      this.$emit('updateSubscribed')
    },
    loadOverlay(val) {
      this.imageOverlayShow = val
    },
    infoButtonHoverHandle(val) {
      this.infoButtonHover = val
    }
  }
}
</script>

<style lang="scss">
.event-local-authority-image {
  height: 3rem;
  width: 3rem;
  object-fit: contain !important;
}
</style>
