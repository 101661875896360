<template>


  <div class="encyclopedia w-100">
    <JayaTitle classType="page-title" moreInfo="Apprenez ici les caractéristiques détaillées de nombreuses espèces et leurs possibles interactions avec la biodiversité." titleName="Encyclopedie"/>
    <b-tabs ref="encyclopediaTabs" fill>
      <b-tab title="Flore">
        <PlantSearch ref="plantSearch" :canDisplayOnBoarding="true"/>
      </b-tab>
      <b-tab title="Faune" id="faunaTab">
        <AnimalSearch ref="animalSearch"/>
      </b-tab>
    </b-tabs>

  </div>


</template>

<script>
import JayaTitle from '@/components/global/JayaTitle.vue'
import PlantSearch from "@/components/plants/PlantSearch.vue"
import AnimalSearch from "@/components/animals/AnimalSearch.vue"
import {BTab, BTabs} from 'bootstrap-vue'
export default {
  name: 'Encyclopedia',
  components: {
    JayaTitle,
    BTabs,
    BTab,
    PlantSearch,
    AnimalSearch,
  },
  methods: {
    scroll(event) {
      if (this.$refs.encyclopediaTabs.currentTab === 0) {
        this.$refs.plantSearch.scroll(event)
      } else if (this.$refs.encyclopediaTabs.currentTab === 1) {
        this.$refs.animalSearch.scroll(event)
      }
    }
  }
}
</script>
