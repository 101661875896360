<template>
  <div class="text-justify">
    <h2 v-if="!getUserZipcode" class="d-flex justify-content-center mb-2 pt-2">Bienvenue</h2>
    Vous êtes dans le module
    <JayaTooltip text="Votre jardin est lié à son environnement. Décrouvrez ici les ressources à votre disposition sur votre territoire en s'inscrivant à des évènements">
      Autour de moi
    </JayaTooltip>
    depuis lequel vous pouvez obtenir toutes les informations provenant des collectivités locales auxquelles vous êtes
    abonné.<br>
    Pour commencer à suivre une
    <JayaTooltip text="Commune, agglomération et/ou département" title="Collectivités">
      collectivité
    </JayaTooltip>,
    il faut que vous ayez renseigné un code postal.
    <div class="d-flex justify-content-center my-1">
      <b-button
          v-if="!getUserZipcode"
          class="m-auto"
          variant="primary"
          @click="openZipCodeUpdate">
        Ajouter un code postal
      </b-button>
    </div>
    <h6 class="text-primary mt-1"> Être abonné vous permet de : </h6>
    <b-list-group flush>
      <b-list-group-item
          class="d-flex align-items-center">
        <span class="mr-1">
        <feather-icon
            class="text-primary"
            icon="CheckIcon"/>
        </span>
        <span>
        Localiser les lieux utiles au jardinage autour de chez vous;
        </span>
      </b-list-group-item>
      <b-list-group-item
          class="d-flex align-items-center">
        <span class="mr-1">
        <feather-icon
            class="text-primary"
            icon="CheckIcon"/>
        </span>
        <span>
        Repérer et s'inscrire aux évènements, en lien avec le jardin, portés par vos collectivités.
        </span>
      </b-list-group-item>
    </b-list-group>

<!--    <AccountSettings ref="accountSettings"/>-->
  </div>
</template>

<script type="text/javascript">
import {BButton, BListGroup, BListGroupItem} from "bootstrap-vue";
import AccountSettings from '@/components/user/AccountSettings.vue';

export default {
  name: 'AroundMeOnboarding',
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    AccountSettings
  },
  computed: {
    getUserZipcode() {
      return this.$store.state.account.user.zip_code
    }
  },
  methods: {
    openZipCodeUpdate() {
      this.$refs.accountSettings.popUpZipCode()
    }
  }
}
</script>