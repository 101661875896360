<template>
  <div class="events">
    <JayaTitle
        classType="page-title"
        clickable
        titleName="Autour de moi"
        @titleClicked="getUserZipcode ? $refs.onboardingPopUp.show() : ''"/>

    <div id="events-view">
      <b-card v-if="!getUserZipcode" class="max-w-lg my-3">
        <EventsOnboarding/>
      </b-card>
      <div v-else>
        <b-tabs ref="aroundMeTabs" fill>
          <b-tab :disabled="!isSubscribedLocalAuthority" title="Carte" @click="noSubscriptions">
            <b-row v-if="isSubscribedLocalAuthority" class="swiper-no-swiping row-1" no-gutters>
              <l-map
                  class="max-w-2xl"
                  ref="aroundMeMap"
                  :center="center"
                  :zoom="zoom"
                  @update:bounds="boundsUpdated"
                  @update:center="centerUpdated"
                  @update:zoom="zoomUpdated">
                <l-tile-layer :attribution="attribution" :url="url"/>
                <l-marker
                    v-for="item in locatedEvents"
                    :key="item.id" ref="eventMarker"
                    :lat-lng="item.location_point"
                    :name="item.id.toString()"
                    @click="newActiveEvent(item)">
                  <l-popup :name="'eventPopUp' + item.id.toString()">
                    {{ item.start_date.toLocaleString() }}<br>
                    <strong class="font-medium-1">{{ item.title }}</strong><br>
                    <div class="w-100 d-flex justify-content-center">
                      <b-button
                          class="p-0"
                          variant="flat-primary"
                          @click="openEvent(item.id)">
                        <feather-icon
                            class="cursor-pointer text-dark"
                            icon="InfoIcon"
                            size="21"/>
                      </b-button>
                    </div>
                  </l-popup>
                </l-marker>
                <l-marker
                    v-for="item in placeList"
                    :key="item.id"
                    ref="placeMarker"
                    :lat-lng="item.location_point"
                    :name="item.id.toString()"
                    @click="newActivePlace(item)">
                  <l-icon
                      :icon-anchor="[20, 50]"
                      :icon-size="[40, 40]"
                      :popup-anchor="[0, -45]">
                    <img
                        :alt="item.type"
                        :src="activePlace ? activePlace.id === item.id ? getPlaceIcon('default_location_marker_active') : getPlaceIcon(item.type) : getPlaceIcon(item.type)">
                  </l-icon>
                  <l-popup>
                    <strong class="font-medium-1">{{ item.title }}</strong><br>
                    <div class="w-100 d-flex justify-content-center">
                      <b-button
                          class="p-0"
                          variant="flat-primary"
                          @click="openPlace(item.id)">
                        <feather-icon
                            class="cursor-pointer text-dark"
                            icon="InfoIcon"
                            size="21"/>
                      </b-button>
                    </div>
                  </l-popup>
                </l-marker>
              </l-map>
            </b-row>
          </b-tab>
          <b-tab :disabled="!isSubscribedLocalAuthority" title="Calendrier" @click="calendarClick">
            <b-row v-if="isSubscribedLocalAuthority" class="row-1 calender">
              <JayaCalendar :attributes="calendarEvents"/>
            </b-row>
          </b-tab>
          <b-tab title="Abonnement" @click="yesSubscriptions">
            <b-alert :show="wantsLocalAuthorityNotAnswered" variant="info" class="mt-1 p-1 max-w-md">
              <p class="text-center">
                Vous ne trouvez aucune des collectivités qui correspondent à l'emplacement de vos jardins dans la liste ci-dessous ?
                Faites-nous savoir si vous souhaitez qu'elles apparaissent dans le futur !
              </p>
              <div class="d-flex justify-content-center mt-1">
                <b-button
                    class="mx-1"
                    size="sm"
                    variant="outline-info"
                    @click="updateWantLa(true)">
                  Pas forcément
                </b-button>
                <b-button
                    class="mx-1"
                    size="sm"
                    variant="info"
                    @click="updateWantLa(false)">
                  Oui, j'aimerais bien
                </b-button>
              </div>
            </b-alert>
            <LocalAuthorityList/>
          </b-tab>
        </b-tabs>

        <div v-if="!subscriptions && isSubscribedLocalAuthority && $refs.aroundMeTabs && $refs.aroundMeTabs.currentTab !== 2" class="max-w-2xl mb-4">
          <b-tabs ref="elementTabs" fill>
            <b-tab
                title="Évènements"
                @click="placesElements = false"/>
            <b-tab
                :disabled="this.$refs.aroundMeTabs ? this.$refs.aroundMeTabs.currentTab !== 0 : ''"
                title="Lieux publics"
                @click="placesElements = true"/>
          </b-tabs>
          <b-row v-if="eventList.length !== 0 || placeList.length !== 0" align-h="center">
            <b-col
                v-for="(item, key) in placeList"
                v-if="placesElements"
                :key="'place-' + key"
                class="mb-1"
                style="max-width:320px; min-width:320px">
              <a @click="newActivePlaceAndOpen(item)">
                <PlaceCard
                    ref="placecard"
                    :class="activePlace ? activePlace.id === item.id ? 'selected-card selected-card-secondary' : '' : '' "
                    :place="item"/>
              </a>
            </b-col>

            <b-col
                v-for="(item, key) in eventList"
                v-if="!placesElements"
                :key="'event-' + key"
                class="mb-1"
                style="max-width:320px; min-width:320px">
              <a @click="newActiveEventAndOpen(item)">
                <EventCard
                    ref="eventcard"
                    :class="activeEvent ? activeEvent.id === item.id ? 'selected-card' : '' : '' "
                    :event="item"
                    @updateSubscribed="updateSubscribed"/>
              </a>
            </b-col>
          </b-row>
          <p v-if="placeList.length === 0 && placesElements">
            Aucun lieu enregistré
          </p>
          <p v-if="eventList.length === 0 && !placesElements">
            Aucun évènement enregistré
          </p>
        </div>
      </div>
    </div>

    <b-modal
        ref="onboardingPopUp"
        centered
        hide-footer
        modal-class="modal-info"
        title="Autour de moi">
      <EventsOnboarding/>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BCard, BCol, BModal, BRow, BTab, BTabs, BAlert} from 'bootstrap-vue'
import JayaTitle from '@/components/global/JayaTitle.vue'
import EventsOnboarding from '@/components/events/EventsOnboarding.vue'
import {LIcon, LMap, LMarker, LPopup, LTileLayer} from 'vue2-leaflet'
import JayaCalendar from '@/components/global/JayaCalendar.vue'
import LocalAuthorityList from '@/components/local-authorities/LocalAuthorityList.vue'
import PlaceCard from "@/components/places/PlaceCard.vue"
import EventCard from "@/components/events/EventCard.vue"
import _ from 'lodash'
import 'leaflet/dist/leaflet.css'

//fix icon in leaflet
import {Icon} from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: 'Events',
  components: {
    BButton,
    BModal,
    BCard,
    BTabs,
    BTab,
    BRow,
    BCol,
    BAlert,
    JayaTitle,
    EventsOnboarding,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    JayaCalendar,
    LocalAuthorityList,
    PlaceCard,
    EventCard,
  },
  data() {
    return {
      subscriptions: false,
      myEvents: true, // Show only user participating events
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 7,
      center: [46.727638, 2.213749], // Default center center of France and dezoomed
      bounds: null,
      aroundMeTabsModel: 0,
      eventList: [],
      placeList: [],
      eventListLimit: 20,
      eventListOffset: 0,
      eventListNext: true,
      placeListLimit: 20,
      placeListOffset: 0,
      placeListNext: true,
      waitScroll: false,
      placesElements: false,
      activePlace: null,
      activeEvent: null,
      displayWantLa: false
    }
  },
  created() {
    this.resetElementsList()
    this.fetchNextElements(true)
  },
  mounted() {
    window.addEventListener('scroll', this.scroll);
    if (this.getUserZipcode) {
      setTimeout(() => {
        if (!this.isSubscribedLocalAuthority) {
          this.aroundMeTabsModel = 2
        } else {
          //mapObject is a property that is part of leaflet
          this.$refs.aroundMeMap.mapObject.invalidateSize();
        }
      }, 100);
    }
    if (this.$store.state.account.user.wants_local_authority == null) {
      this.displayWantLa = true
    }

    //Center the map
    const activeGarden = this.$store.state.garden.gardens.find(garden => {
      return garden.is_active
    })
    if (activeGarden.local_authorities.length > 0) {
      const priorityLevels = ['region', 'department', 'epci', 'city'];
      let mainLocalAuthorityInfos;
      for (let level of priorityLevels) {
        mainLocalAuthorityInfos = activeGarden.local_authorities_infos.find(e => e.level === level);
        if (mainLocalAuthorityInfos !== undefined) {
          break;
        }
      }
      if (mainLocalAuthorityInfos) {
        let active_local_auth = this.$store.state.localAuthority.subscriptions.find(localAuth => {
          return localAuth.id === mainLocalAuthorityInfos.id
        })

        this.center = active_local_auth && active_local_auth.center ? active_local_auth.center : this.center
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll);
  },
  computed: {
    locatedEvents() {
      return this.eventList.filter(e => e.location_point != null)
    },
    calendarEvents() {
      let resultArray = []
      if (this.activeEvent) {
        resultArray.push({
          key: this.activeEvent.title,
          customData: {
            description: this.activeEvent.title,
          },
          highlight: {color: 'green', fillMode: 'light'},
          dates: [
            {
              start: this.activeEvent.start_date,
              end: this.activeEvent.end_date
            }
          ]
        })
      }
      resultArray.push({
        key: 'today',
        customData: {
          description: 'Aujourd\'hui',
        },
        highlight: {color: 'gray', fillMode: 'light'},
        dates: new Date()
      })
      this.eventList.map(event => {
        resultArray.push({
          key: event.id,
          order: 1,
          customData: {
            description: event.title,
          },
          dot: {
            color: 'green'
          },
          popover: {
            label: event.title,
          },
          dates: [
            {
              start: new Date(event.start_date),
              end: new Date(event.end_date)
            }
          ]
        })
      })
      return resultArray
    },
    isSubscribedLocalAuthority() {
      return (this.$store.state.localAuthority.subscriptions != null && this.$store.state.localAuthority.subscriptions.length > 0)
    },
    orderedEvents() {
      return _.orderBy(this.eventList, '!is_subscribed')
    },
    getUserZipcode() {
      const activeGarden = this.$store.state.garden.gardens.find(garden => {
        return garden.is_active
      })
      return activeGarden.zip_code
    },
    wantsLocalAuthorityNotAnswered() {
      return this.$store.state.account.user.wants_local_authority === null
    }
  },
  methods: {
    resetElementsList() {
      this.eventList = []
      this.placeList = []
      this.myEvents = true
      this.eventListOffset = 0
      this.eventListNext = true
      this.placeListOffset = 0
      this.placeListNext = true
    },
    fetchNextElements(fetchBoth = false) {
      let payload = {}
      // Show only events on the subscribed local authorities
      let subscribedLocalAuthorities = this.$store.state.localAuthority.subscriptions
      let local_authorities = []
      for (const subscribedLocalAuthority of subscribedLocalAuthorities) {
        if (subscribedLocalAuthority != null) {
          local_authorities.push(subscribedLocalAuthority.id)
        }
      }
      payload.local_authority = local_authorities

      if (this.placesElements || fetchBoth) {
        payload.limit = this.placeListLimit
        payload.offset = this.placeListOffset
        this.$store.dispatch("place/fetchPlaces", payload)
            .then(response => {
              this.placeListNext = response.next;
              this.placeListOffset = this.placeListOffset + this.placeListLimit
              this.placeList = this.placeList.concat(response.results);
              this.waitScroll = false
              setTimeout(() => document.getElementById("swiper").swiper.resize.resizeHandler())
            }).catch(() => {
          this.waitScroll = false
          this.$notify({
            time: 4000,
            color: "danger",
            title: "Erreur",
            text: "Une erreur est survenue lors de la récupération des évènements"
          })
        });
      }
      if (!this.placesElements || fetchBoth) {
        payload.limit = this.eventListLimit
        payload.offset = this.eventListOffset
        payload.ordering = 'start_date'
        payload.subscriptions = this.myEvents ? 'true' : 'false' // Get participating events ?
        payload.after_date = new Date(Date.now()).toISOString() // Get only current events
        this.$store.dispatch("event/fetchEvents", payload)
            .then(response => {
              // Tag the event where an user is participating and add the result to the list
              for (let i = 0; i < response.results.length; i++) {
                response.results[i].is_subscribed = this.myEvents
              }
              this.eventList = this.eventList.concat(response.results);
              // Should we load unsubscribed events ?
              if (this.myEvents && response.count <= this.eventListOffset + this.eventListLimit) {
                this.myEvents = false
                this.eventListNext = true
                this.eventListOffset = 0
                this.fetchNextElements()
              } else {
                this.eventListNext = response.next
                this.eventListOffset = this.eventListOffset + this.eventListLimit
                this.waitScroll = false
              }
              setTimeout(() => document.getElementById("swiper").swiper.resize.resizeHandler())
            }).catch(() => {
          this.waitScroll = false
          this.$notify({
            time: 4000,
            color: "danger",
            title: "Erreur",
            text: "Une erreur est survenue lors de la récupération des évènements"
          })
        });
      }
    },
    zoomUpdated(zoom) {
      this.zoom = zoom
    },
    centerUpdated(center) {
      if (center != null) {
        this.center = center
      }
    },
    boundsUpdated(bounds) {
      this.bounds = bounds
    },
    yesSubscriptions() {
      this.subscriptions = true
      this.resetElementsList()
    },
    noSubscriptions() {
      this.subscriptions = false
      this.placesElements = false
      this.resetElementsList()
      this.fetchNextElements(true)
    },
    calendarClick() {
      if (this.$refs.elementTabs && this.$refs.elementTabs.currentTab) {
        this.$refs.elementTabs.currentTab = 0
        this.$refs.elementTabs.activateTab(0)
        this.activePlace = null
        this.placesElements = false
      }
      this.noSubscriptions()
    },
    scrollToEvent() {
      document.getElementById("events-view").scrollIntoView();
    },
    scroll(element) {
      // If we are at the bottom of the scroll bar during scrolling, load the next events
      if (((!this.placesElements && this.eventListNext) || (this.placesElements && this.placeListNext)) && document.body.scrollHeight - window.scrollY - window.innerHeight < 50) {
        if (!this.waitScroll) {
          this.waitScroll = true
          this.currentScroll = element.target.scrollTop
          this.fetchNextElements()
        }
      }
    },
    updateSubscribed(event) {
      this.waitScroll = true
      let eventUpdate = this.eventList.find(e => e.id === event)
      eventUpdate.is_subscribed = !eventUpdate.is_subscribed
    },
    getPlaceIcon(type) {
      let img = ""
      try {
        img = require("@/assets/images/icons/other/" + type + ".svg")
      } catch {
        img = require("@/assets/images/icons/other/default_location_marker.svg")
      }
      return img
    },
    newActiveEventAndOpen(event) {
      this.newActiveEvent(event)
      this.$refs.eventMarker.filter(item => item.name === event.id.toString())[0].mapObject.openPopup()
    },
    newActiveEvent(event) {
      this.$refs.elementTabs.currentTab = 0
      this.$refs.elementTabs.activateTab(0)
      this.placesElements = false
      this.centerUpdated(event.location_point)
      this.activePlace = null
      this.activeEvent = event
    },
    newActivePlaceAndOpen(place) {
      this.newActivePlace(place)
      this.$refs.placeMarker.filter(item => item.name === place.id.toString())[0].mapObject.openPopup()
    },
    newActivePlace(place) {
      this.$refs.elementTabs.currentTab = 1
      this.$refs.elementTabs.activateTab(1)
      this.placesElements = true
      this.centerUpdated(place.location_point)
      this.activeEvent = null
      this.activePlace = place
    },
    openEvent(eventId) {
      this.$refs.eventcard.find(eventCard => eventCard.event.id === eventId).popUpMoreInfo = true
    },
    openPlace(placeId) {
      this.$refs.placecard.find(placeCard => placeCard.place.id === placeId).popUpMoreInfo = true
    },
    updateWantLa(value) {
      let payload = {wants_local_authority: value}
      this.$store.dispatch("account/updateProfile", payload)
          .then(() => {
            this.$notify({
              color: "primary",
              time: 4000,
              title: "C'est noté",
              text: "Votre souhait de voir votre collectivité sur Hortilio a été pris en compte, merci pour votre retour",
            })
            this.displayWantLa = false
          })
          .catch(() => {
            this.$notify({
              color: "danger",
              time: 4000,
              title: "Erreur",
              text: "Votre souhait de voir votre collectivité sur Hortilio n'a pas été pris en compte, merci de rééssayer ultérieurement",
            })
          })
    }
  },
  watch: {
    '$store.state.app.windowWidth'() {
      setTimeout(() => {
        if (this.$refs.elementTabs) {
          this.$refs.elementTabs.activateTab(this.$refs.elementTabs.currentTab)
        }
        this.$refs.aroundMeTabs.activateTab(this.$refs.aroundMeTabs.currentTab)
      }, 100)
    },
    getUserZipcode() {
      setTimeout(() => {
        let tab = (this.getUserZipcode && this.isSubscribedLocalAuthority) ? this.$refs.aroundMeTabs.currentTab : 2
        this.$refs.aroundMeTabs.activateTab(tab)
      }, 100)
    },
    aroundMeTabsModel() {
      setTimeout(() => {
        let tab = (this.getUserZipcode && this.isSubscribedLocalAuthority) ? this.$refs.aroundMeTabs.currentTab : 2
        this.$refs.aroundMeTabs.activateTab(tab)
      }, 100)
    }
  }
}
</script>

<style lang="scss">
.events {
  width: 100%;

  .image {
    max-height: 30vh;
    width: 100%;
    object-fit: contain;
  }

  .options-buttons {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    transform: translateY(50%);
    bottom: 0;
    right: 0;
  }

  .leaflet-popup-content {
    margin: 5px 16px;
    line-height: 1.4;
  }

  .calendar-container {
    height: 5rem;
  }

  .row-1 {
    min-height: 269px;
    height: 45vh;
    padding-bottom: 10px;
  }

  .incard {
    color: #626262 !important;
    width: 100%;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
