<template>
  <div v-if="localAuthority">
    <!-- Photo de garde -->
    <div class="jaya-top-row mb-1">
      <viewer :images="getImage" class="jaya-container">
        <img v-for="src in getImage" :key="src" :alt="localAuthority.name" :src="src" class="jaya-image">
      </viewer>
    </div>

    <p v-for="(part,id) in localAuthority.description.split('\n')" :key="id" class="m-0 mt-1 text-justify">{{part}}</p>
  </div>
</template>

<script>
import {BCol, BRow} from "bootstrap-vue";

export default {
  name: "LocalAuthorityInfo",

  components: {
    BRow,
    BCol,
  },

  props: {
    localAuthority: {
      type: Object,
      default: null
    }
  },
  computed: {
    getImage() {
      if (this.localAuthority.photo_url) {
        return [process.env.VUE_APP_BACKEND_URL + '/..' + this.localAuthority.photo_url + "&size=960"]
      }
      return [require("@/assets/images/default_pictures/local_authority_default.jpg")]
    }
  },
}
</script>

<style scoped>

</style>