<template>
  <div>
    <JayaTitle
        classType="page-title"
        class="py-1"
        titleName="Paramètres du compte"/>

    <b-card class="max-w-md">
      <h2 class="text-center font-medium-1 mt-2">Mes jardins</h2>
      <div class="account-settings">
        <b-list-group flush>
          <b-list-group-item
              class="d-flex justify-content-between">
            <div>
            <span class="font-weight-bold">
              Créer un nouveau jardin
            </span>
              <br>
            </div>
            <b-button
                class="ml-1 p-50"
                pill
                size="sm"
                variant="flat-primary"
                @click="$router.push({name: 'manage-garden', params: {mode:'create'}})">
              <feather-icon
                  icon="PlusIcon"
                  size="16"/>
            </b-button>
          </b-list-group-item>

          <b-list-group-item
              v-if="manyGardens"
              class="d-flex justify-content-between">
            <div>
            <span class="font-weight-bold">
              Changer de jardin actif
            </span>
              <br>
              <span class="font-small-3">
              {{activeGardenExtendedName}}
            </span>
            </div>
            <b-button
                class="ml-1 p-50"
                pill
                size="sm"
                variant="flat-primary"
                @click="changeActiveGardenIsPrompt=true">
              <feather-icon
                  icon="Edit2Icon"
                  size="16"/>
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </div>

      <h2 class="text-center font-medium-1 mt-2">Personnalisation</h2>
      <div class="account-settings">
        <b-list-group flush>
          <b-list-group-item
              class="d-flex justify-content-between">
            <div>
            <span class="font-weight-bold">
              Apparence de l'application
            </span>
              <br>
              <span class="font-small-3">
              {{ adaptativeColor ? 'Adaptative' : 'Original'}}
            </span>
            </div>
            <div class="d-flex align-items-center">
              <b-form-checkbox v-model="adaptativeColor"
                               class="ml-1"
                               :disabled="activeGarden.local_authorities_infos.length===0"
                               switch
                               :value="true" @change="setAdaptativeColor"/>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>

      <h2 class="text-center font-medium-1 mt-2">Mes Identifiants</h2>
      <div class="account-settings">
        <b-list-group flush>
          <b-list-group-item
              class="d-flex justify-content-between">
            <div>
            <span class="font-weight-bold">
              Email
            </span>
              <br>
              <span class="font-small-3">
              {{ user.email }}
            </span>
            </div>
          </b-list-group-item>

          <b-list-group-item
              class="d-flex justify-content-between">
            <div>
            <span class="font-weight-bold">
              Prénom
            </span>
              <br>
              <span class="font-small-3">
              {{ user.first_name }}
            </span>
            </div>
            <b-button
                class="ml-1 p-50"
                pill
                size="sm"
                variant="flat-primary"
                @click="popUpEditName">
              <feather-icon
                  icon="Edit2Icon"
                  size="16"/>
            </b-button>
          </b-list-group-item>

          <b-list-group-item
              class="d-flex justify-content-between">
            <div>
            <span class="font-weight-bold">
              Nom
            </span>
              <br>
              <span class="font-small-3">
              {{ user.last_name }}
            </span>
            </div>
            <b-button
                class="ml-1 p-50"
                pill
                size="sm"
                variant="flat-primary"
                @click="popUpEditName">
              <feather-icon
                  icon="Edit2Icon"
                  size="16"/>
            </b-button>
          </b-list-group-item>

          <b-list-group-item
              class="d-flex justify-content-between">
            <div class="font-weight-bold">
              Mot de passe
            </div>
            <b-button
                class="ml-1 p-50"
                pill
                size="sm"
                variant="flat-primary"
                @click="change_password">
              <feather-icon
                  icon="Edit2Icon"
                  size="16"/>
            </b-button>
          </b-list-group-item>

          <b-list-group-item
              class="d-flex justify-content-between">
            <div class="font-weight-bold">
              Se déconnecter
            </div>
            <b-button
                class="ml-1 p-50"
                pill
                size="sm"
                variant="flat-warning"
                @click="logout">
              <feather-icon
                  icon="LogOutIcon"
                  size="16"/>
            </b-button>
          </b-list-group-item>

          <b-list-group-item
              class="d-flex justify-content-between">
            <div class="font-weight-bold">
              Supprimer le compte
            </div>
            <b-button
                class="ml-1 p-50"
                pill
                size="sm"
                variant="flat-danger"
                @click="deleteAccountPrompt=true">
              <feather-icon
                  icon="TrashIcon"
                  size="16"/>
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-card>

    <validation-observer v-slot="{invalid}">
      <b-modal
          v-model="changeActiveGardenIsPrompt"
          :ok-disabled="invalid"
          cancel-title="Annuler"
          ok-title="Changer"
          title="Changer de jardin actif"
          variant="primary"
          @ok="setMainGarden(newActiveGarden)">

        <b-alert show variant="warning" class="p-1">
          <p>Vous êtes sur le point de modifier votre jardin actif sur Hortilio</p>
          <p class="text-dark font-weight-normal">
            La plateforme <strong>Hortilio s'adapte à la position de votre jardin actif</strong>. Vous allez donc observer
            des changements sur : <i>le contenu de votre jardin (onglet "Mon jardin"), les conseils, les évènements, les
            lieux d'intérêt autour de vous, les plantes indigènes...</i>
          </p>
        </b-alert>

        <p>Votre jardin actif actuel est <i>{{activeGardenExtendedName}}</i></p>

        <b-form-group label="Sélectionnez le nouveau jardin actif">
          <validation-provider v-slot="{errors}" name="Jardin actif" rules="required" immediate>
          <b-form-select
              v-model="newActiveGarden"
              :options="gardenChoices"/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
        </b-form-group>
      </b-modal>
    </validation-observer>


    <validation-observer v-slot="{invalid}">
      <b-modal
          v-model="editNameIsPrompt"
          :ok-disabled="invalid"
          cancel-title="Annuler"
          ok-title="Valider"
          title="Editer votre prénom et nom"
          variant="primary"
          @ok="editName">
        <b-form-group label="Prénom">
          <validation-provider v-slot="{errors}" name="Prénom" rules="max:30">
            <b-form-input v-model="editingFirstName" placeholder="Prénom"/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Nom">
          <validation-provider v-slot="{errors}" name="Nom" rules="max:150">
            <b-form-input v-model="editingLastName" placeholder="Nom"/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
        </b-form-group>

        <b-form-text v-if="!validName" class="text-danger">
          Le nom est trop long ou contient des caractères invalides.
        </b-form-text>
      </b-modal>
    </validation-observer>

    <b-modal
        v-model="deleteAccountPrompt"
        :ok-disabled="!passwordExists"
        cancel-title="Annuler"
        centered
        ok-title="Supprimer"
        ok-variant="danger"
        title="Supprimer mon compte"
        variant="danger"
        @ok="deleteAccount">

      <b-alert show variant="danger" class="p-1">
        Êtes-vous certain de vouloir supprimer votre compte ? Une fois supprimé, celui-ci ne pourra plus être
        récupéré.
      </b-alert>

      <b-form-group label="Si oui, renseignez votre mot de passe :">
        <validation-provider v-slot="{errors}" rules="required">

          <b-input-group class="flex-nowrap">
            <b-input-group-prepend is-text>
              <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"/>
            </b-input-group-prepend>
            <b-form-input
                ref="password"
                v-model="password"
                :type="passwordFieldType"
                class="w-full mt-6"
                label-placeholder="Mot de passe"
                name="password"
                placeholder="Mot de passe"/>
          </b-input-group>
          <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
          <b-form-text v-if="passwordError" class="text-danger">
            Mot de passe incorrect.
          </b-form-text>
        </validation-provider>
      </b-form-group>

      <div class="cursor-pointer text-primary" @click="forgotPassword">
        Mot de passe oublié ?
      </div>
    </b-modal>
  </div>
</template>

<script type="text/javascript">
import JayaTitle from '@/components/global/JayaTitle.vue'
import {mapState} from "vuex"
import {
  BAlert,
  BButton,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormText,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BModal,
  BCard,
  BFormSelect,
} from "bootstrap-vue";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {max, required} from "vee-validate/dist/rules";
import {togglePasswordVisibility} from '@core/mixins/ui/forms'

extend('required', {...required, message: 'Ce champ est requis'});
extend('max', {...max, message: 'Le champ "{_field_}" doit faire moins de {length} caractères'});

export default {
  name: 'AccountSettings',
  components: {
    BModal,
    BListGroup,
    BListGroupItem,
    BButton,
    BFormCheckbox,
    BFormInput,
    BFormText,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BAlert,
    BCard,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    JayaTitle,
  },
  mixins: [togglePasswordVisibility],
  mounted() {
    this.adaptativeColor = this.$store.state.account.user.color_adaptation
  },
  data() {
    return {
      editNameIsPrompt: false,
      validName: true,
      deleteAccountPrompt: false,
      editingFirstName: '',
      editingLastName: '',
      password: '',
      passwordError: false,
      adaptativeColor: false,
      changeActiveGardenIsPrompt: false,
      newActiveGarden: null,
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user,
    }),
    passwordExists() {
      return this.password !== ''
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    gardens() {
      return this.$store.state.garden.gardens
    },
    manyGardens() {
      return this.gardens.length > 1
    },
    gardenChoices() {
      return this.gardens.filter(e => !e.is_active).map(e => {
        return {value: e, text: e.name + (Object.keys(e.zip_code_details).length !== 0 ?
              ' (' + e.zip_code_details.name + ')' : '')}
      })
    },
    activeGarden() {
      return this.gardens ? this.gardens.find(g => g.is_active) : null
    },
    activeGardenExtendedName() {
      return this.activeGarden ? this.activeGarden.name + (Object.keys(this.activeGarden.zip_code_details).length !== 0 ?
          ' (' + this.activeGarden.zip_code_details.name + ')' : '') : ''
    },
  },
  methods: {
    popUpEditName() {
      this.validName = true
      this.editingFirstName = this.user.first_name
      this.editingLastName = this.user.last_name
      this.editNameIsPrompt = true
    },
    editName() {
      this.$store.dispatch('account/updateProfile', {
        first_name: this.editingFirstName,
        last_name: this.editingLastName
      })
          .then(() => {
            this.$loading(false)
            this.editNameIsPrompt = false
            this.$notify({
              time: 4000,
              color: "primary",
              title: "Profil mis à jour",
              text: "Votre nom a bien été mis à jour"
            })
          })
          .catch((error) => {
            this.$loading(false)
            if (error === "invalid_name") {
              this.validName = false
              this.editNameIsPrompt = true
            } else if (error === "server_error") {
              this.editNameIsPrompt = false
              this.$notify({
                time: 4000,
                color: "danger",
                title: "Erreur technique",
                text: "Une erreur est survenue lors de l'édition de vos informations, veillez rééssayer plus tard"
              })
            }
          })
    },
    logout() {
      this.$loading(true);
      this.$logout()
          .then(() => {
            this.$loading(false)
            this.$notify({
              time: 4000,
              color: "primary",
              title: "Déconnecté",
              text: "Vous avez bien été déconnecté, à bientôt !"
            })
            this.$router.push({name: 'login'})
          })
          .catch(() => {
            this.$loading(false)
            this.$notify({
              time: 4000,
              color: "danger",
              title: "Erreur technique",
              text: "Une erreur est survenue à la déconnexion, veillez rééssayer plus tard"
            })
          })
    },
    change_password() {
      this.settingsIsPopUp = false
      this.$router.push({name: "change-password"});
    },
    deleteAccount() {
      this.$loading(true)
      let payload = {password: this.password}
      this.$store.dispatch('account/deleteAccount', payload)
          .then(() => {
            this.deleteAccountPrompt = false
            this.$notify({
              title: "Compte supprimé",
              text: "Votre compte a bien été supprimé, en espérant vous revoir très vite au jardin.",
              color: "warning",
              time: 4000
            })
            this.$loading(false)
            this.$router.push({name: "login"})
          })
          .catch(error => {
            this.$loading(false)
            this.deleteAccountPrompt = true
            if (error === "authenticationError") {
              this.passwordError = true
            } else {
              this.$notify({
                title: "Erreur",
                text: "Une erreur est survenue à la suppression de votre compte, veuillez réessayer. Si le problème persiste, veuillez nous contacter.",
                color: "danger",
                time: 4000
              })
            }
          })
    },
    forgotPassword() {
      this.deleteAccountPrompt = false
      this.settingsIsPopUp = false
      this.$router.push({name: "forgot-password"})
    },

    getMainLocalAuthorityInfos() {
      const priorityLevels = ['city', 'epci', 'department', 'region'];
      let mainLocalAuthorityInfos;

      for (let level of priorityLevels) {
        mainLocalAuthorityInfos = this.activeGarden.local_authorities_infos.find(e => e.level === level);
        if (mainLocalAuthorityInfos !== undefined) {
          return mainLocalAuthorityInfos;
        }
      }
      return undefined
    },
    setAdaptativeColor() {
      const mainLocalAuthorityInfos = this.getMainLocalAuthorityInfos()

      let payload = {"color_adaptation": this.adaptativeColor}

      this.$loading(true)

      this.$store.dispatch('account/updateProfile', payload)
          .then(() => {
            // No change fo baseline
            let colors = this.$setColors(this.$store.state.account.user.color_adaptation, mainLocalAuthorityInfos)
            this.$store.commit("localAuthority/SET_COLOR", colors)
            this.$store.commit("localAuthority/SET_TRANSPARENT_HEADER",
                this.$store.state.account.user.color_adaptation && mainLocalAuthorityInfos &&
                mainLocalAuthorityInfos.transparent_header)
            // No change for logo
            this.$loading(false)
          })
          .catch(() => {
            this.$loading(false)
          })
    },
    setMainGarden(garden) {
      if (!garden.is_active) {
        this.$loading(true)
          this.$store.dispatch("garden/updateGarden", {id: garden.id, is_active: true})
            .then(() => {
              this.$store.commit("feedback/SET_UPDATE_BIODIV_SCORE", "update_score")
              this.$router.push({name: 'jayaccueil'})
              this.$loading(false)
            })
            .catch(() => {
              this.$notify({
                time: 4000,
                color: 'danger',
                title: 'Une erreur est survenue',
                text: 'Un problème est survenu lors de l\'édition du jardin, veuillez réessayer plus tard'
              })
              this.$loading(false)
            })

      }
    },
  },
}
</script>

<style lang="scss">
.account-settings {
  .sub-button {
    margin-top: 5px;
  }
}
</style>
