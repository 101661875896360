<template>
  <b-card :class="subscribedCard" class="mb-0"
      noBody
      style="max-width:19rem; min-width:19rem; min-height:21rem; max-height:21rem;">
    <b-card-body class="d-flex flex-column justify-content-between position-relative border-top-light">
      <b-button
          v-if="isMember"
          class="position-absolute position-right-0 position-top-0 m-50"
          size="sm"
          variant="info"
          @click="administrate">
        <feather-icon
            class="mr-25"
            icon="SlidersIcon"/>
        <span class="align-middle">Administrer</span>
      </b-button>
      <img :alt="getImageDescription" :src="getImage" class="card-img-top pb-50"
          style="object-fit: contain; max-height:10.5rem; min-height:10.5rem">
      <b-card-title
          class="d-flex justify-content-center align-items-center pb-50 m-0 text-center font-weight-bolder text-break overflow-hidden"
          style="max-height:4rem; min-height:4rem">
        {{ localAuthority.name }}
      </b-card-title>
      <b-card-footer class="p-0 pt-1"
          style="max-height:4rem; min-height:4rem">
        <b-row align-h="center" class="w-100" no-gutters>
          <b-col class="text-center col-4 d-flex justify-content-center align-items-center">
            <feather-icon
                v-b-hover="infoButtonHoverHandle"
                :class="infoButtonHover ? 'text-secondary' : 'text-info'"
                class="cursor-pointer"
                icon="InfoIcon"
                size="28"
                @click="popUpMoreInfo = true"/>
          </b-col>
          <b-col v-if="!isMainLocalAuthority" class="text-center col-8">
            <b-button
                :variant="isSubscribed ? 'secondary' : 'primary'"
                size="sm"
                @click="isSubscribed ? unsubscribe(localAuthority) : subscribePopUp = true">
              <feather-icon
                  :class="{'fill-current': isSubscribed}"
                  class="mr-25 fill-primary"
                  icon="HeartIcon"/>
              <span class="align-middle">
                {{ isSubscribed ? "Se désabonner" : "S'abonner" }}
             </span>
            </b-button>
          </b-col>
          <b-col v-else class="text-center col-8">
            <JayaTooltip :title="localAuthority.name"
                         class="text-primary font-weight-bolder"
                         color="info"
                         text="Ceci est votre collectivité territoriale principale correspondant à votre code postal.
                          Vous y êtes automatiquement abonné. ">
              <feather-icon
                  class="mr-25"
                  icon="HomeIcon"/>
              <span>
                Abonné par défaut
              </span>
            </JayaTooltip>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card-body>

    <b-modal
        v-model="subscribePopUp"
        centered
        cancel-title="Annuler"
        cancel-variant="outline-warning"
        ok-title="S'abonner"
        ok-variant="primary"
        @ok="performSubscription(localAuthority)"
        title="Abonnement">
      <p>Vous êtes sur le point de vous abonner à la collectivité <span class="font-italic">{{ localAuthority.name }}
      </span> : <br>vous aurez accès aux évènements, lieux et fiches pratiques qu'elle partage.</p>
    </b-modal>

    <b-modal
        v-model="unSubscribePopUp"
        centered
        cancel-title="Annuler"
        cancel-variant="outline-warning"
        ok-title="Se désabonner"
        ok-variant="primary"
        @ok="performUnsubscription(localAuthority)"
        title="Désabonnement">
      <p>Vous allez vous désabonner de la collectivité <span class="font-italic">{{ localAuthority.name }}</span> :
        <br>vous n'aurez plus accès aux évènements, lieux et fiches pratiques qu'elle partage.</p>
    </b-modal>

    <b-modal
        ref="popUp"
        v-model="popUpMoreInfo"
        :title="localAuthority.name"
        body-class="pt-0"
        centered
        hide-footer
        scrollable>
      <LocalAuthorityInfo :localAuthority="localAuthority"/>
    </b-modal>
  </b-card>
</template>

<script type="text/javascript">
import LocalAuthorityInfo from "@/components/local-authorities/LocalAuthorityInfo";
import {
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardTitle,
  BCol,
  BModal,
  BRow
} from "bootstrap-vue";

export default {
  name: "local-authority-card",
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardTitle,
    BModal,
    BCardFooter,
    BRow,
    BCol,
    LocalAuthorityInfo,
  },
  data() {
    return {
      popUpMoreInfo: false,
      subscribePopUp: false,
      unSubscribePopUp: false,
      infoButtonHover: false,
    }
  },
  props: {
    localAuthority: {
      type: Object,
      default: null
    }
  },
  computed: {
    getImage() {
      return this.localAuthority.photo_url
          ? process.env.VUE_APP_BACKEND_URL + '/..' + this.localAuthority.photo_url + "&size=960"
          : require('@/assets/images/default_pictures/local_authority_default.jpg')
    },
    getImageDescription() {
      return "Image de " + this.title
    },
    isMember() {
      if (this.$store.state.localAuthority.memberOf == null) {
        return false
      }
      return this.$store.state.localAuthority.memberOf.id === this.localAuthority.id;
    },
    isSubscribed() {
      if (this.$store.state.localAuthority.subscriptions == null) {
        return false
      }
      return this.$store.state.localAuthority.subscriptions.some(localAuthority => localAuthority.id === this.localAuthority.id);
    },
    isMainLocalAuthority() {
      // Check that the user's zip code is in the zipcodes of the local auth
      for (const garden of this.$store.state.garden.gardens) {
        if (garden.local_authorities.includes(this.localAuthority.id)) {
          return true
        }
      }
      return false
    },
    hasGardenInLocalAuthority() {
      return this.$store.state.garden.gardens.some(gd => {
        return gd.local_authority === this.localAuthority.id
      })
    },
    subscribedCard() {
      if (this.isMainLocalAuthority) {
        return 'selected-card'
      }
      if (this.isSubscribed) {
        return 'selected-card selected-card-secondary'
      }
      return ''
    }
  },
  methods: {
    performSubscription(localAuthority) {
      return new Promise((resolve, reject) => {
        this.$loading(true)
        let params = {id: localAuthority.id}
        this.$store.dispatch("localAuthority/subscribe", params)
            .then(() => {
              this.$notify({
                time: 4000,
                title: "Abonnement",
                color: "primary",
                text: "Vous êtes maintenant abonné à " + localAuthority.name
              })
              this.$loading(false)
              resolve()
            }).catch(() => {
            this.$notify({
              time: 4000,
              color: "danger",
              title: "Erreur",
              text: "Une erreur est survenue, veuillez réessayer plus tard"
            })
          this.$loading(false)
          this.subscribePopUp = false
          reject()
        })
      })
    },

    performUnsubscription(localAuthority) {
      let params = {id: localAuthority.id}

      return new Promise((resolve, reject) => {
        this.$store.dispatch("localAuthority/unsubscribe", params).then(() => {
          this.$notify({
            time: 4000,
            title: "Abonnement",
            color: "danger",
            text: "Vous n'êtes désormais plus abonné à " + localAuthority.name
          })
        }).catch(() => {
          this.$notify({
            time: 4000,
            color: "danger",
            title: "Erreur",
            text: "Une erreur est survenue, veuillez réessayer plus tard"
          })
          reject()
        })
      })
    },
    unsubscribe() {
      this.unSubscribePopUp = true
    },

    administrate() {
      this.$router.push('/local-authorities/admin')
    },

    infoButtonHoverHandle(val) {
      this.infoButtonHover = val
    }
  }
}
</script>

<style lang="scss" media="screen">

</style>
