<template>
  <div class="local-authority-list max-w-2xl">
    <SearchBar class="inline-flex" @update-search="search"/>
    <b-row align-h="center">
      <b-col
          v-for="localAuthority in localAuthoritiesFiltered"
          :key="localAuthority.id"
          class="py-1 d-flex justify-content-center flex-grow-0">
        <LocalAuthorityCard :localAuthority="localAuthority"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LocalAuthorityCard from '@/components/local-authorities/LocalAuthorityCard.vue'
import {BCol, BRow} from "bootstrap-vue";
import SearchBar from "@/components/global/SearchBar.vue"

export default {
  name: 'LocalAuthorityList',
  components: {
    SearchBar,
    LocalAuthorityCard,
    BRow,
    BCol,
  },
  data() {
    return {
      localAuthorities: [],
      localAuthoritiesFiltered: [],
    }
  },
  methods: {
    search(searchText) {

      // Clone the array to avoid multiple filtering pass
      let localAuthoritiesFiltered = [...this.localAuthorities];

      // TODO manage multiple local authorities for member and
      localAuthoritiesFiltered.sort((la1, la2) => {

        // Put the member local authority in first
        if (this.$store.state.localAuthority.memberOf != null) {
          if (la1.id === this.$store.state.localAuthority.memberOf.id) {
            return -1;
          }
          if (la2.id === this.$store.state.localAuthority.memberOf.id) {
            return 1;
          }
        }

        // Put the subscribed local authority at the beginning
        if (this.$store.state.localAuthority.subscriptions != null) {
          // check if la1 is a local authority in user subscribtions
          if (this.$store.state.localAuthority.subscriptions.some(localAuthority => localAuthority.id === la1.id)) {
            return -1;
          }
          if (this.$store.state.localAuthority.subscriptions.some(localAuthority => localAuthority.id === la2.id)) {
            return 1;
          }
        }

        // Sort by name by default
        return la1.name.localeCompare(la2.name)
      })

      // Search the local authority
      localAuthoritiesFiltered = localAuthoritiesFiltered.filter(la => {
        // Remove accent for search
        // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463
        let name = la.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        let search = searchText.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        return name.toLowerCase().includes(search.toLowerCase())
      })

      this.localAuthoritiesFiltered = localAuthoritiesFiltered
    }
  },
  mounted() {
    this.$store.dispatch("localAuthority/fetchLocalAuthorities")
        .then(localAuthorities => {
          this.localAuthorities = localAuthorities
          this.search("")
          setTimeout(() => document.getElementById("swiper").swiper.resize.resizeHandler(), 100)
        })
        .catch(() => {
          this.$notify({
            time: 4000,
            color: "danger",
            title: "Erreur",
            text: "Une erreur est survenue lors de la récupération des collectivités"
          })
        })
  }
}
</script>

<style lang="scss">
.local-authority-list {
  margin-bottom: 50px;
}
</style>
