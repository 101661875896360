<template>
  <validation-observer v-slot="{ invalid, handleSubmit}">
    <div class="new-question">
      <b-alert v-if="!plantIdentification" class="text-center mb-1" show variant="warning">
        Assurez-vous que votre question n'a pas déjà une réponse avant
        de la poster.
      </b-alert>

      <b-form @submit.prevent="handleSubmit(submitQuestion)">

        <!--Ma question-->
        <b-form-group label="Ma question">
          <validation-provider v-slot="{errors}" immediate name="Titre" rules="required|maxlength:128">
            <b-form-input
                autofocus
                ref="questionTitle"
                v-model="newQuestion"
                placeholder="Ma question"/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
        </b-form-group>

        <!--Détails-->
        <b-form-group label="Détails de la question">
          <validation-provider v-slot="{errors}" name="Titre" rules="maxlength:16384">
            <b-form-textarea
                v-model="newQuestionDetails"
                placeholder="Détails..."/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
        </b-form-group>

        <!--Détails-->
        <b-form-group label="Thème de la question">
          <b-form-select
              v-model="selectedTheme"
              :options="themeOptions"/>
        </b-form-group>

        <!--Spécifique plante-->
        <b-form-group>
          <b-row v-if="!plantIdentification" class="mt-1">
            <b-col>
              Votre question est-elle spécifique à une plante ?
            </b-col>
            <b-col cols="3">
              <b-form-checkbox
                  v-model="plantSearchOn"
                  class="custom-control-primary"
                  switch>
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon"/>
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon"/>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" class="text-center font-weight-bold text-primary">
              {{ plantSearchOn ? selectedPlant : ''}}
            </b-col>
          </b-row>
          <PlantSearch
              v-show="plantSearchOn"
              :activeFilter="false"
              :preloadContent="false"
              :selectedPlantId="selectedPlantId"
              listType="simple"
              size="150px"
              v-on:newSelection="newPlantSelect"/>
        </b-form-group>

        <b-row class="justify-content-around align-items-center mt-1">
          <div v-if="plantIdentification && !newQuestionPhoto"
              class="p-2 m-3 mb-6 mt-0 text-secondary bg-secondary-light text-center rounded-lg">
            N'oubliez pas d'ajouter une photo pour faciliter l'identification.
          </div>

          <img v-if="newQuestionPhoto" :src="newQuestionPhoto.url" alt="new-photo"
              style="max-width: 100%; max-height: 250px; margin-bottom: 5px;"/>

          <b-button
              class="h-full"
              variant="outline-primary"
              @click="promptUploadPhoto = true">
            {{ newQuestionPhoto ? 'Modifier la photo' : 'Ajouter une photo' }}
          </b-button>
        </b-row>

        <b-row class="justify-content-center mt-1">
          <b-button
              :disabled="!validateQuestion"
              type="submit"
              variant="primary">
            Soumettre ma question
          </b-button>
        </b-row>
      </b-form>

      <JayUpload :active.sync="promptUploadPhoto"
          title="Changer de photo"
          @on-cancel="newQuestionPhoto = null"
          @on-error="newQuestionPhoto = null"
          @on-success="addPhoto"/>
    </div>
  </validation-observer>
</template>

<script type="text/javascript">
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BCollapse,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormText,
  BFormTextarea,
  BInputGroup,
  BRow,
} from "bootstrap-vue";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
import JayUpload from '@/components/global/JayUpload'
import PlantSearch from "@/components/plants/PlantSearch.vue"
import {required} from "vee-validate/dist/rules";

extend('required', {...required, message: 'Ce champ est requis'});
extend('maxlength', {
  validate(value, args) {
    return value.length <= args.length;
  }, params: ['length'], message: 'Le champ "{_field_}" doit faire moins de {length} caractères'
});

export default {
  name: "new-question",
  components: {
    BButton,
    BCard,
    BAlert,
    BForm,
    BFormText,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BInputGroup,
    BRow,
    BCol,
    BCollapse,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    PlantSearch,
    JayUpload
  },
  data() {
    return {
      newQuestion: '',
      newQuestionDetails: '',
      newQuestionPhoto: null,
      plantSearchOn: false,
      selectedPlant: 'Choissisez une plante',
      selectedPlantId: null,
      promptUploadPhoto: false,

      selectedTheme: null,
      themeOptions: [
        {value: null, text: 'Sélectionnez un thème', disabled: true},
        {value: 'OT', text: 'Autre'},
        {value: 'WA', text: 'Arrosage'},
        {value: 'TR', text: 'Taille'},
        {value: 'HA', text: 'Récolte'},
        {value: 'FL', text: 'Floraison'},
        {value: 'PI', text: 'Identification de plante'},
      ]
    }
  },
  props: {
    text: {
      type: String,
      default: "Ce contenu n'est pas disponible"
    },
    plantIdentification: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.plantIdentification) {
      this.selectedTheme = 'Identification de plante'
      this.newQuestion = 'Identification de plante'
    }
  },
  computed: {
    validateQuestion() {
      return this.newQuestion !== '' && this.newQuestion.length <= 128 &&
          (!this.plantIdentification || this.newQuestionPhoto) &&
          this.newQuestionDetails.length <= 16384
    }
  },
  methods: {
    addPhoto(photo) {
      this.newQuestionPhoto = photo
    },
    newPlantSelect(value) {
      this.selectedPlant = value.common_name
      this.selectedPlantId = value.id
    },
    init_data() {
      this.newQuestion = ''
      this.newQuestionDetails = ''
      this.type = 'OT'
      this.selectedPlant = ''
      this.selectedPlantId = null
      this.newQuestionPhoto = null
    },
    addPlantIdentificationQuizAnwers(value) {
      this.plantIdentificationQuizAnwers = value
    },
    submitQuestion() {
      this.$loading(true)
      let payload = {
        title: this.newQuestion,
        type: this.selectedTheme ? this.selectedTheme : 'OT',
        plant: this.selectedPlantId,
        photo: this.newQuestionPhoto
      }
      if (this.newQuestionPhoto) {
        payload.photo = this.newQuestionPhoto.id
      }
      if (this.newQuestionDetails !== '') {
        payload.details = this.newQuestionDetails
      }
      this.$store.dispatch('forum/postQuestion', payload)
          .then(() => {
            this.$emit('new-post')
            this.$notify({
              color: "primary",
              title: "Question envoyée",
              text: "Votre question a été soumise",
              time: 4000
            })
          })
          .catch(() => {
            this.$notify({
              color: "danger",
              title: "Erreur question",
              text: "Désolé, votre question n'a pas pu être posée",
              time: 4000
            })
          })
          .finally(() => {
            this.init_data()
            this.$loading(false)
          })
    }
  },
  watch: {
    plantSearchOn(value) {
      if (!value) {
        this.selectedPlant = "Choisissez une plante"
        this.selectedPlantId = null
      }
    }
  }
}
</script>
