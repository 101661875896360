<template>
  <div class="animal-card cursor-pointer">
    <b-card class="overflow-hidden mb-25" no-body>
      <b-row class="animal-card-height" no-gutters @click="animalCardClick">
        <b-col :class="imageClass" cols="3" style="background-color: var(--secondary-light)">
          <b-card-img
              :alt="imageDescription"
              :class="imageClass"
              :src="getImage"
              left/>
        </b-col>
        <b-col class="d-flex align-items-center pl-1 pr-50 animal-card-height" cols="8">
          <b-card-body class="p-0 pr-1 text-truncate">
            <b-card-text class="m-0">
              <h4 class="m-0 font-weight-bolder text-truncate" v-html="title === '' ? 'N/A' : title"/>
              <div class="text-truncate">{{ subtitle }}</div>
            </b-card-text>
          </b-card-body>
          <slot></slot>
        </b-col>
        <b-col class="d-flex flex-column align-items-center justify-content-center pr-50 animal-card-height" cols="1">
          <img v-if="animalNotifications.invasive" alt="Icône animal envahissant" class="my-25 animal-card-icon" src="@/assets/images/icons/other/invasive.svg">
        </b-col>
      </b-row>
    </b-card>

      <JayaPopUp ref="animalInfoPopUp" name="FicheAnimal">
        <b-card class="max-w-lg">
          <AnimalInfo
              :animalId="animalIdMoreInfos"
              :title="title"
              @close-delete="stopPopUp"/>
        </b-card>
    </JayaPopUp>
  </div>
</template>

<script type="text/javascript">
import {BCard, BCardBody, BCardImg, BCardText, BCol, BRow,} from "bootstrap-vue";
import JayaPopUp from "@/components/global/JayaPopUp.vue"
import AnimalInfo from "@/components/animals/AnimalInfo.vue"

export default {
  name: "animal-card",
  components: {
    BCard,
    BCardBody,
    BCardImg,
    BCardText,
    BRow,
    BCol,
    JayaPopUp,
    AnimalInfo
  },
  props: {
    title: {
      type: String,
      default: "N/A"
    },
    subtitle: {
      type: String,
      default: "N/A"
    },
    animalId: {
      type: Number,
      default: -1
    },
    animalNotifications: {
      type: Object,
      default() {
        return {}
      }
    },
    clickToGiveId: {
      type: Boolean,
      default: false
    },
    animalPhotos: {
      type: Array,
      default: function () {
        return []
      }
    },
    animalPictos: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
  data() {
    return {
      animalIdMoreInfos: null,
      imageClass: "jaya-card-image"
    }
  },
  computed: {
    getImage() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.imageClass = "picto"
      if (this.animalPhotos.length > 0) {
        this.imageClass = "jaya-card-image"
        return process.env.VUE_APP_BACKEND_URL + '/..' + this.animalPhotos[0].photo_url + "&size=200"
      }
      return require("@/assets/images/icons/fauna/butterfly.svg")
    },
    imageDescription() {
      return "Image de " + this.title
    },
  },
  methods: {
    animalCardClick() {
      if (this.clickToGiveId) {
        this.$emit('cardClicked', this.animalId)
      } else {
        this.openMoreInfo()
      }
    },
    openMoreInfo() {
      this.animalIdMoreInfos = this.animalId
      this.$refs.animalInfoPopUp.openPopUp()
      this.popUpMoreInfo = true
    },
    stopPopUp(event) {
      if (event) {
        this.popUpMoreInfo = false
      }
    },
    transmitUpdate() {
      this.$emit("animal-update")
    },
  }
}
</script>

<style lang="scss">
.animal-card {
  position: relative;
  width: 100%;
  margin: auto;
  max-width: 40rem;

  .animal-card-height {
    height: 84px;
    @media screen and (min-width: 1200px) {
      height: 112px;
    }
  }

  .animal-score-text {
    text-shadow: 1px 2px 2px black;
  }

  .picto {
    height: 84px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 1200px) {
      height: 112px;
    }

    .picto {
      height: 70px;
      @media screen and (min-width: 1200px) {
        height: 90px;
      }
    }
  }

  .animal-card-icon {
    height: 25px;
    width: auto;
    @media screen and (min-width: 1200px) {
      height: 35px;
    }
  }
}
</style>
