<template>
  <div ref="animalSearch" class="animal-search">
    <SearchBar v-if="!getAdvice && (forceFilterPopup || $store.state.app.windowWidth < 768)" :filter="activeFilter" @filter-clicked="$refs.popUpFilters.toggle()" @update-search="search"/>
    <SearchBar v-if="!getAdvice && $store.state.app.windowWidth >= 768 && !forceFilterPopup" @update-search="search"/>
    <b-container fluid v-if="listType==='cards'">
      <b-row>
        <b-col v-if="activeFilter && !forceFilterPopup && $store.state.app.windowWidth >= 768" cols="4">
          <div class="encyclopedia-page-content w-full">
            <AnimalFilter @filter-validated="setFilters"/>
          </div>
        </b-col>
        <b-col :cols="activeFilter && !forceFilterPopup && $store.state.app.windowWidth >= 768 ? 8 : 12" class="w-100 p-0">
          <div class="encyclopedia-page-content">
            <transition-group class="incard" name="list">
              <AnimalCard
                  v-for="animal in animalList.results"
                  :key="animal.id"
                  :clickToGiveId="clickToGiveId"
                  :animal-photos="animal.photos"
                  :animal-pictos="animal.pictos"
                  :animalId="animal.id"
                  :subtitle="animal.infos_taxon.lb_nom"
                  :title="animal.common_name"
                  v-on:cardClicked="emitSelection(animal)"/>
            </transition-group>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div v-if="listType==='simple'" :style="'height: ' + activeSize + ';'" class="encyclopedia-page-content overflow-y-scroll">
      <b-list-group class="incard" flush>
        <b-list-group-item
            v-for="animal in animalList.results"
            :key="animal.id">
          <div :class="animalClass(animal.id)" class="animal-item cursor-pointer" @click="emitSelection(animal)">
            <strong>
              {{ animal.common_name }}
            </strong>
            <span>
            {{ animal.infos_taxon.lb_nom }}
          </span>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>

    <b-modal ref="popUpFilters" class="holamundo" hide-footer scrollable title="Filtres animaux">
      <AnimalFilter @filter-validated="setFilters"></AnimalFilter>
    </b-modal>
  </div>
</template>

<script>
import SearchBar from "@/components/global/SearchBar.vue"
import AnimalCard from "@/components/animals/AnimalCard.vue"
import AnimalFilter from "@/components/animals/AnimalFilter.vue"
import {BCol, BContainer, BListGroup, BListGroupItem, BModal, BRow} from "bootstrap-vue";

export default {
  name: 'AnimalSearch',
  components: {
    SearchBar,
    AnimalCard,
    AnimalFilter,
    BRow,
    BCol,
    BContainer,
    BModal,
    BListGroup,
    BListGroupItem
  },
  data() {
    return {
      searchOffset: 0,
      waitScroll: false,
      searchText: '',
      animalList: [],
      filters: null,
      activeSize: '0px',
      selectedAnimal: null,
      containerElement: null //for scrolling purposes
    }
  },
  props: {
    size: {
      type: String,
      default: 'calc(100vh - 210px)'
    },
    listType: {
      type: String,
      default: 'cards'
    },
    searchLimit: {
      type: Number,
      default: 20
    },
    preloadContent: {
      type: Boolean,
      default: true
    },
    activeFilter: {
      type: Boolean,
      default: true
    },
    forceFilterPopup: {
      type: Boolean,
      default: false
    },
    clickToGiveId: {
      type: Boolean,
      default: false
    },
    getAdvice: {
      type: Boolean,
      default: false
    },
    selectedAnimalId: {
      type: Number,
      default: null
    },
    vGAnimalOnly: {
      type: Boolean,
      default: null
    }
  },
  mounted() {
    this.setCorrectParentForScrolling()
    if (this.preloadContent) {
      this.activeSize = this.size
      this.$store.dispatch("animal/search")
        .then(response => {
          this.animalList = response.data
          // Update the width when the animals are set, make sure the update is
          // processed by adding a timeout.
          if (document.getElementById("swiper")) {
            setTimeout(() => document.getElementById("swiper").swiper.resize.resizeHandler())
          }
        })
        .catch(() => {
          this.animalList = []
        })
    }
    if (this.selectedAnimalId !== null) {
      this.selectedAnimal = this.selectedAnimalId
    }
  },
  destroyed() {
    if (this.containerElement) {
      this.containerElement.removeEventListener('scroll', this.selfScroll)
    }
  },
  methods: {
    search(searchText) {
      this.activeSize = this.size
      this.searchText = searchText
      this.searchOffset = 0
      let payload = {
        search: this.searchText,
        limit: this.searchLimit,
      }
      if (this.filters) {
        payload = {...payload, ...this.filters}
      }
      this.$store.dispatch("animal/search", payload)
          .then(response => {
            this.animalList = response.data
          })
          .catch(() => {
            this.animalList = []
          })
    },
    setCorrectParentForScrolling() {
      if (this.$refs.animalSearch
          && this.$refs.animalSearch.parentElement) {
        if (this.$refs.animalSearch.parentElement.id === "animalSearchTab") {
          this.containerElement = this.$refs.animalSearch.parentElement
          this.containerElement.addEventListener('scroll', this.selfScroll)
        } else if (this.$refs.animalSearch.parentElement.parentElement
            && this.$refs.animalSearch.parentElement.parentElement.id === "content") {
          this.containerElement = this.$refs.animalSearch.parentElement.parentElement
          this.containerElement.addEventListener('scroll', this.selfScroll)
        }
      }
    },
    scroll(event) {
      // If we are at the bottom of the scroll bar during scrolling, load the next animals
      if (this.animalList.next && document.body.scrollHeight - window.scrollY - window.innerHeight < 50) {
        this.currentScroll = event.target.scrollTop
        this.loadNext()
      }
    },
    selfScroll(event) {
      if ((event.target.scrollHeight - (event.target.scrollTop + event.target.clientHeight)) < 50) {
        this.loadNext()
      }
    },
    loadNext() {
      if (!this.waitScroll) {
        this.waitScroll = true
        let payload = {
          search: this.searchText,
          limit: this.searchLimit,
          offset: this.searchOffset + this.searchLimit,
        }
        if (this.filters) {
          payload = {...payload, ...this.filters}
        }
        this.$store.dispatch("animal/search", payload)
            .then(response => {
              this.animalList.next = response.data.next;
              this.searchOffset = this.searchOffset + this.searchLimit
              this.animalList.results = this.animalList.results.concat(response.data.results);
              this.waitScroll = false
            }).catch(() => {
          this.waitScroll = false
        });
      }

    },
    setFilters(event) {
      this.searchOffset = 0
      let filters = {}
      for (let item in event.filters) {
        if (event.filters[item].length) {
          filters[item] = event.filters[item]
        }
      }
      this.filters = filters
      this.$store.dispatch("animal/search",
          {...{search: this.searchText}, ...{limit: this.searchLimit,}, ...filters})
          .then(response => {
            this.animalList = response.data
            if (event.close) {
              this.$refs.popUpFilters.hide()
            }
          })
          .catch(() => {
            this.animalList = []
          })
    },
    emitSelection(selectedAnimal) {
      this.selectedAnimal = selectedAnimal.id
      this.$emit('newSeleciton', selectedAnimal)
    }
  },
  computed: {
    animalClass() {
      var vm = this
      return function (id) {
        if (vm.selectedAnimal === id) {
          return "green-animal"
        }
        return ""
      }
    }
  }
}
</script>

<style lang="scss">
.list-enter-active, .list-leave-active {
  transition: all 1s;
}

.animal-search {
  width: 100%;

  .encyclopedia-page-content {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .incard {
    width: 100%;
  }

  .animal-item:active, .animal-item:hover {
    color: rgba(var(--vs-primary), 1);
    background: rgba(var(--vs-primary), 0.2);
    border-radius: 10px;
  }

  .green-animal {
    color: rgba(var(--vs-warning), 1);
    background: rgba(var(--vs-warning), 0.2);
    border-radius: 10px;
  }
}
</style>
