<template>
  <div class="biodiversity-quiz">
    <form-wizard
        :subtitle="null"
        :title="null"
        backButtonText="Précédent"
        class="p-0"
        color="var(--primary)"
        errorColor="var(--danger)"
        finish-button-text="Résultat"
        nextButtonText="Suivant"
        stepSize="xs"
        @on-complete="postAnswers"
        @update:startIndex="changeActive">
      <tab-content
          v-for="(question, index) in questions"
          v-if="question.conditionnal_answer === null"
          :key="index"
          :before-change="validateContent"
          :title="question.title">
        <TextBlock :title="question.title"/>

        <h5>{{ question.text }}</h5>

        <b-row class="mb-2">
          <b-col cols="12">
            <ul>
              <li v-for="(answer, index) in question.possible_answers" :key="index" class="checkbox-quiz-element">
                <b-form-checkbox
                    v-if="question.multiple_choice"
                    v-model="quizAnswers[question.order]"
                    :value="answer.id"
                    @input="modifyListIfNothing">
                  {{ answer.text }}
                </b-form-checkbox>
                <b-form-radio-group v-else>
                  <b-form-radio
                      v-model="quizAnswers[question.order]"
                      :value="answer.id">
                    {{ answer.text }}
                  </b-form-radio>
                </b-form-radio-group>
              </li>
            </ul>
          </b-col>
        </b-row>
        <b-row
            v-for="(addQuestion, index) in question.related_questions"
            v-if="quizAnswers[question.order].includes(addQuestion.conditionnal_answer)"
            :key="index">
          <b-col cols="12">
            <TextBlock :title="addQuestion.title"/>

            <h5>{{ addQuestion.text }}</h5>

            <ul>
              <div v-for="(addAnswer, index) in getAnswers(addQuestion.order)" :key="index">
                <li class="checkbox-quiz-element">
                  <b-form-radio-group>
                    <b-form-radio
                        v-model="quizAnswers[addQuestion.order]"
                        :value="addAnswer.id">
                      {{ addAnswer.text }}
                    </b-form-radio>
                  </b-form-radio-group>
                </li>
              </div>
            </ul>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import TextBlock from '@/components/global/JayaTextBlock.vue'
import {BCol, BFormCheckbox, BFormRadio, BFormRadioGroup, BRow} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
    FormWizard,
    TabContent,
    TextBlock
  },
  data() {
    return {
      quizAnswers: [],
      surfaceUsed: '',
      nothingClickedLast: false,
      questions: null,
      quizId: null,
      activeQuestionList: [],
      activeQuestion: 0,
      answersLoading:false
    }
  },
  mounted() {
    this.$store.dispatch("feedback/getQuiz", {type: "biodiversité"})
        .then(response => {
          this.questions = response.data[0].questions.sort((a, b) => a.order - b.order)
          this.quizId = response.data[0].id
          for (let question of this.questions) {
            // naturaly the order is opposed to the one in the db
            question.possible_answers.reverse()
            if (question.multiple_choice) {
              var answers = []
              // Fill the first question's answers with the categories of the plants in the garden.
              if (question.order === 0) {
                for (let plant of this.$store.state.garden.gardens.find(garden => {
                  return garden.is_active
                }).plants) {
                  let growth_habit = this.getGrowthHabit(plant.plant)
                  if (growth_habit !== "" && answers.filter(e => e === question.possible_answers.find(
                      qu => {
                        return qu.text === growth_habit
                      }).id).length === 0) {
                    answers.push(question.possible_answers.find(qu => {
                      return qu.text === growth_habit
                    }).id)
                  }
                }
              }
              this.quizAnswers.push(answers)
            } else {
              this.quizAnswers.push(null)
            }
            if (question.conditionnal_answer == null) {
              this.activeQuestionList.push(question)
            }
          }
        })
        .catch(() => {
        })
  },
  methods: {
    modifyListIfNothing(event) {
      if (this.activeQuestion == 2) {
        // the answers contains "rien"
        if (event.includes(28)) {
          // if it is the last added on, we must set the garden content to
          // the answer that is "rien"
          const nothingIndex = event.indexOf(28)
          if (nothingIndex == event.length - 1) {
            this.quizAnswers[4] = [28]
          } else {
            this.quizAnswers[4].splice(nothingIndex, 1)
          }
        }
      }
    },
    validateContent() {
      if (this.answersLoading) {
        return false
      }
      let question = this.activeQuestionList[this.activeQuestion]
      let value = this.quizAnswers[question.order]
      /*
        if the question is mandatory, it can'be an empty array
        or null if it cannot have more than one.
      */
      if (question.is_mandatory) {
        if (!value || (value instanceof Array && value.length == 0)) {
          return false
        }
      }
      if (question.related_questions) {
        for (let qu of question.related_questions) {
          if (value.includes(qu.conditionnal_answer)) {
            if (qu.is_mandatory && !this.quizAnswers[qu.order]) {
              return false
            }
          } else {
            this.quizAnswers[qu.order] = null
          }
        }
      }
      return true
    },
    validateSurface() {
      return this.surfaceUsed != ''
    },
    getAnswers(order) {
      return this.questions.filter(question => question.order == order)[0].possible_answers
    },
    changeActive(event) {
      this.activeQuestion = event
    },
    postAnswers() {
      this.answersLoading = true
      this.$loading(true)
      let answers_given = []
      for (let item in this.quizAnswers) {
        let answers = this.quizAnswers[item]
        if (answers instanceof Array) {
          if (answers.length) {
            let ans = {question: {order: parseInt(item)}, answer: answers}
            answers_given.push(ans)
          }
        } else {
          if (answers) {
            let ans = {question: {order: parseInt(item)}, answer: [answers]}
            answers_given.push(ans)
          }
        }
      }
      let payload = {
        garden: this.$store.state.garden.gardens.find(garden => {
          return garden.is_active
        }).id,
        quiz: this.quizId, answers_given: answers_given
      }
      this.$store.dispatch("feedback/answerQuiz", payload)
          .then(() => {
            this.$loading(false)
            this.$emit('quiz-finished')
          })
          .catch(() => {
            this.$loading(false)
            this.$emit('quiz-error')
          })
    },
    getGrowthHabit(plant) {
      if (plant.growth_habit === "Tree") {
        return "Arbre"
      }
      if (plant.growth_habit === "Vine") {
        return "Plante Grimpante"
      }
      if (plant.growth_habit === "Shrub" || plant.growth_habit === "Subshrub") {
        return "Arbuste"
      }
      return ""
    }
  },
  watch: {
    '$store.state.feedback.updateBiodivScore'(val) {
      if (val === "update_questions") {
        let question = this.questions.find(el => el.order == 0)
        let newFirstAnswers = []
        for (let plant of this.$store.state.garden.gardens.find(garden => {
          return garden.is_active
        }).plants) {
          let growth_habit = this.getGrowthHabit(plant.plant)
          if (growth_habit !== "" && !(plant.growth_habit in newFirstAnswers)) {
            newFirstAnswers.push(question.possible_answers.find(qu => {
              return qu.text === growth_habit
            }).id)
          }
        }
        let diff = this.quizAnswers[0].filter(value => !newFirstAnswers.includes(value))
        for (let to_remove of diff) {
          let index = this.quizAnswers[0].indexOf(to_remove)
          this.quizAnswers[0].splice(index, index + 1)
        }
        this.$store.commit("feedback/SET_UPDATE_BIODIV_SCORE", "Done")
      }
    }
  }
}
</script>

<style lang="scss">
.biodiversity-quiz {
  width: 100%;
  height: 100%;

  ul {
    list-style-type: none;
    padding-left: 10px;
  }

  .wizard-header {
    padding: 0;
  }

  validateContent
  .checkbox-quiz-element {
    margin-bottom: 8px;
    font-size: 16px;
  }

  .vue-form-wizard .wizard-card-footer {
    padding: 0 10px;
  }

}
</style>
