<template>
  <div>
    <onBoardingPopOver targetId="gardenTab" :step="1"
                       zoomValue="10" moduleInfo="l'accueil"
                       title="Mon jardin"
                       text="Vous êtes actuellement sur l'accueil de votre jardin"
                       moduleName="home"/>
    <onBoardingPopOver targetId="forumTab" :step="2"
                       zoomValue="10" moduleInfo="l'accueil"
                       title="Une question ? "
                       text="Participez au forum : échangez avec les jardiniers sur les problématiques que vous rencontrez, aidez à identifier des plantes ..."
                       moduleName="home"/>
    <onBoardingPopOver targetId="aroundMeTab" :step="3"
                       zoomValue="10" moduleInfo="l'accueil"
                       title="Autour de vous"
                       text="Découvrez autour de vous les événements et les lieux en lien avec le jardinage et la biodiversité : ils sont proposés par les collectivités auxquelles vous êtes abonné !"
                       moduleName="home"/>
    <onBoardingPopOver targetId="encyclopediaTab" :step="4"
                       zoomValue="10" moduleInfo="l'accueil"
                       title="Encyclopédie"
                       text="Explorez l'encyclopédie pour composer votre jardin et obtenir des conseils d'entretien.
                       Découvrez également la faune des jardins et déclarez les observations que vous en faites ! "
                       moduleName="home"/>
    <onBoardingPopOver targetId="biodivTab" :step="5"
                       title="Biodiversité" moduleInfo="l'accueil"
                       :text="activeGarden.is_balcony ? 'Découvrez l\'impact de votre jardin sur la biodiversité' :
                       'Découvrez l\'impact de votre jardin sur la biodiversité. Et calculez votre score de biodiversité'"
                       moduleName="home"/>
    <onBoardingPopOver targetId="biodivTab2" :step="6"
                       title="Planches potagères" moduleInfo="l'accueil"
                       text="Créez vos planches potagères et découvrez les associations de cultures qui sont bénéfiques pour votre potager !"
                       moduleName="home"/>
    <onBoardingPopOver targetId="navbar-jaya" :step="7"
                       :placement="'top'"
                       title="De nombreux outils" moduleInfo="l'accueil"
                       text="Découvrez également un grand nombre d'outils (carnet de notes, fiches pratiques ...) !"
                       moduleName="home"/>
  </div>
</template>

<script>

import onBoardingPopOver from "@/components/onboarding/onBoardingPopOver.vue";

export default {
  name: 'HomeOnBoarding',
  components: {
    onBoardingPopOver
  },
  computed: {
    activeGarden() {
      return this.$store.state.garden.gardens.find(g => g.is_active)
    }
  }
}
</script>
